export const CHECK_EMAIL = "CHECK_EMAIL";
export const CHECK_IS_LOGGED = "CHECK_IS_LOGGED";
export const SET_EMAIL_VALUE = "SET_EMAIL_VALUE";
export const EMAIL_EXISTS = "EMAIL_EXISTS";
export const SET_EMAIL_ERROR= "SET_EMAIL_ERROR";
export const SET_PASSWORD_VALUE = "SET_PASSWORD_VALUE";
export const SET_VERIFY_PASSWORD_VALUE = "SET_VERIFY_PASSWORD_VALUE";
export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SET_LOGIN_ERROR_MESSAGE = "SET_LOGIN_ERROR_MESSAGE";
export const LOGIN = "LOGIN";

export const EMAIL_TO_CREATE = "EMAIL_TO_CREATE";
export const SAVE_USER = "SAVE_USER";
export const SAVE_USER_ID = "SAVE_USER_ID";
export const REGISTER = "REGISTER";
export const REGISTER_EMAIL = "REGISTER_EMAIL";
export const SAVE_USER_TYPE = "SAVE_USER_TYPE";
export const SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE";
export const SET_ACTIVATION_CODE = "SET_ACTIVATION_CODE";
export const UPDATE_CGU_STATE = "UPDATE_CGU_STATE";
export const UPDATE_ONBOARDING_STATE = "UPDATE_ONBOARDING_STATE";
export const UPDATE_CODE_ACTIVATION = "UPDATE_CODE_ACTIVATION";
export const LOGOUT = "LOGOUT";

export const SET_BLOCKED_ACCOUNT = "SET_BLOCKED_ACCOUNT";

export const SET_AUTH = "SET_AUTH";
export const SET_REMEMBER_ME = "SET_REMEMBER_ME";
export const SET_REDIRECTION = "SET_REDIRECTION";
export const LOGIN_WITH_COOKIE = "LOGIN_WITH_COOKIE";

export const checkEmail = () => ({
	type : CHECK_EMAIL,
});

export const login = () => ({
	type : LOGIN,
});
export const register = () => ({
	type : REGISTER,
});

export const registerEmail = () => ({
	type : REGISTER_EMAIL,
});

export const saveUser = (session, dataUser) => ({
	type : SAVE_USER,
	session,
	dataUser
});
export const saveUserId = (idUser) => ({
	type : SAVE_USER_ID,
	idUser
});

export const emailExists = (value) => ({
	type: EMAIL_EXISTS,
	value
});

export const emailToCreate = (value) => ({
	type: EMAIL_TO_CREATE,
	value
});

export const setEmailValue = (value) => ({
	type: SET_EMAIL_VALUE,
	value,
});

export const setPasswordValue = (value) => ({
	type: SET_PASSWORD_VALUE,
	value,
});
export const setVerifyPasswordValue = (value) => ({
	type: SET_VERIFY_PASSWORD_VALUE,
	value,
});

export const setEmailError = (value) => ({
	type: SET_EMAIL_ERROR,
	value
});

export const setErrorMessage = (msg) => ({
	type: SET_ERROR_MESSAGE,
	msg
});

export const setLoginErrorMessage = (msg) => ({
	type: SET_LOGIN_ERROR_MESSAGE,
	msg
});

export const setSuccessMessage = (msg) => ({
	type: SET_SUCCESS_MESSAGE,
	msg
});

export const saveUserType = (userType) => ({
	type: SAVE_USER_TYPE,
	userType
});

export const updateCguState = () => ({
	type: UPDATE_CGU_STATE
});

export const updateOnboardingState = () => ({
	type: UPDATE_ONBOARDING_STATE
});

export const checkIsLogged = () => ({
	type: CHECK_IS_LOGGED,
});

export const setActivationCode = (value) => ({
	type: SET_ACTIVATION_CODE,
	value
});

export const updateCodeActivation = (idExt, idSociete) => ({
	type: UPDATE_CODE_ACTIVATION,
	idExt,
	idSociete
});

export const logout = () => ({
	type: LOGOUT
});
export const setBlockedAccount = (bool) => ({
	type: SET_BLOCKED_ACCOUNT,
	bool
});
export const setAuth = (bool) => ({
	type: SET_AUTH,
	bool
});

export const setRememberMe = (bool) => ({
	type: SET_REMEMBER_ME,
	bool
});

export const setRedirection = (bool) => ({
	type: SET_REDIRECTION,
	bool
});

export const loginWithCookie = () => ({
	type: LOGIN_WITH_COOKIE,
});




