import { SET_ERROR_API_MESSAGE } from "../actions/apiFlag";

export const initialState = {
	errorApiMessage: false
};

const apiFlag = (state = initialState, action = {}) => {
	switch (action.type) {
	case SET_ERROR_API_MESSAGE : {
		return {
			...state,
			errorApiMessage: action.bool
		};
	}
	default :
		return state;
	}
};

export default apiFlag;