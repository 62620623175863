import React from "react";

import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ component: Component, auth, ...rest }) => {

	// Add your own authentication on the below line.
	// L'accès au route privé est conditionné par l'existence d'un idUser dans le localStorage
	// Très léger en terme de sécurité d'accès mais compensé par une redirection après un échec du middleware checkIsLogged() qui est appelé sur chacune des routes
	const isLogged = window.localStorage.getItem("idUser");

	return (
		<Route
			{...rest}
			render={props =>
				auth || isLogged ? (
					<Component {...props} />
				) : (
				// eslint-disable-next-line react/prop-types
					<Redirect to={{ pathname: "/redirectionToHome", state: { from: props.location } }} />
				)
			}
		/>
	);
};

PrivateRoute.propTypes = {
	component: PropTypes.object,
	auth: PropTypes.bool,
	rest: PropTypes.string
};

export default PrivateRoute;
