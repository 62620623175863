import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const Button = ({ value, onClick, disabled, src, color, children }) => {
	return (
		<button
			type="submit"
			className={`button ${color}`}
			value={value}
			onClick={onClick}
			disabled={disabled}
		>
			{value}
			{src &&
			children
			}
		</button>
	);
};

Button.propTypes = {
	value: PropTypes.string,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	src: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.func,
		PropTypes.string,
	]),
	color: PropTypes.string,
	children: PropTypes.node,
};

export default Button;