import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import Wrapper from "../../Layout/Wrapper/Wrapper";
import Button from "../../shared/Button/Button";
import Arrow from "../../../assets/icons/arrow-next.svg";
import Email from "../../../assets/icons/email.svg";
import Phone from "../../../assets/icons/phone.svg";
import Dots from "../../../assets/icons/dots.svg";
import Input from "../../shared/Input/Input";
import Alerte from "../../shared/Alerte/Alerte";
import errorIcon from "../../../assets/icons/error.svg";
import checkIcon from "../../../assets/icons/check.svg";
import Modal from "../../../containers/LoginTunnel/ActivationCode/Modal";
import axios from "axios";

const ActivationCode = ({
	setActivationCode,
	activationCode,
	checkIsLogged,
	checkActivationCode,
	errorActivation,
	successActivation,
	showModal,
	setShowModal,
	ACSource
}) => {
	const [ACParams, setACParams] = useState(false);
	const [sourceInfos, setSourceInfos] = useState([]);
	useEffect(() => {
		if (activationCode) {
			setACParams(true);
		} else {
			return null;
		}
		if (activationCode || (ACSource)) {
			axios.get("https://www.prevandcare.com/api/internal/textes-codes.json")
				.then((res) => {
					res.data.map((source) => {
						if (source.source == ACSource) {
							setSourceInfos(source);
						}

					});
				})
				.catch(() => {
					console.log("erracsource");
				});
		}
	}, []);
	// Vérifie les identifiants de sessions
	useEffect(checkIsLogged, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// Affiche les informations au clique sur aide
	const [help, setHelp] = useState();

	const history = useHistory();

	const stateOnboarding = window.localStorage.getItem("stateOnboarding");

	const handleSubmit = (e) => {
		if (!activationCode) {
			// S'il n'y a pas de code d'activation on ouvre la modal de confirmation
			e.preventDefault();
			setShowModal(true);
		} else {
			e.preventDefault();
			// Sinon on envoie une requête pour vérifier si le code d'activation existe ou non
			checkActivationCode();
			setTimeout(() => {
				// Message erreur/succès stocké dans le localstorage. A refactoriser  pour le mettre dans le store
				const success = window.localStorage.getItem("success");
				if (success && stateOnboarding === "0") {
					history.push("/onBoarding");
				}
			}, 2500);
		}
	};


	return (
		<>
			<Wrapper fade="fade">
				<div id="colonneFormSmall">
					<h1>{ACSource
						? sourceInfos.titrePage
						: "Votre entreprise a souscrit à Prev&Care ? "}</h1>
					<p>{ACSource ?
						sourceInfos.textePage
						: "Pour bénéficier du service gratuitement, merci de renseigner le code d’activation fourni par votre entreprise (ex : ENTREPRISE-0000)."}</p>
					<form type="submit" method="post" onSubmit={handleSubmit}>
						<Input disabled={ACParams ? "disabled" : ""}
							label={activationCode ? "" : "Code d'activation"}
							onFocus={() => null} type="text"
							value={activationCode}
							onChange={(e) => setActivationCode(e.target.value)} src={Dots}/>
					</form>
					<a href="#" onClick={() => setHelp(true)} className="link text-pink">Besoin d&apos;aide ?
Contactez-nous -&gt;</a>
					{help &&
<ul id="contact-us" className="fade">
	<li>
		<strong>Par mail ou téléphone</strong><br/>du lundi au samedi de 9h à 19h
	</li>
	<li className="container-contact">
		<a href="tel:0033187666621"><img
			src={Phone}/>&nbsp;01&nbsp;87&nbsp;66&nbsp;66&nbsp;21</a>
		<a href="mailto:support@prevandcare.com"><img src={Email}/> Mail</a>
	</li>
</ul>
					}
					<div className="navigation-bottom">
						<span>1 sur 3</span>
						<div className="container-button-next">
							<Button color="orange" type={"text"}
								value={activationCode ? "Validez votre code" : "Je\u00a0n’ai\u00a0pas\u00a0de\u00a0code"}
								src={Arrow}
								onClick={handleSubmit}/>
						</div>
					</div>
				</div>
				<div id="colonneImgBig">
					<div></div>
				</div>
				{errorActivation &&
<Alerte src={errorIcon} activeClass="error" text={errorActivation}/>
				}
				{successActivation &&
<Alerte src={checkIcon} activeClass="success" text={successActivation}/>
				}
			</Wrapper>
			{showModal &&
<Modal/>
			}
		</>
	);
};

ActivationCode.propTypes = {
	setActivationCode: PropTypes.func,
	activationCode: PropTypes.string,
	checkIsLogged: PropTypes.func,
	checkActivationCode: PropTypes.func,
	setShowModal: PropTypes.func,
	showModal: PropTypes.bool,
	errorActivation: PropTypes.string,
	successActivation: PropTypes.string,
	ACSource: PropTypes.string
};

export default ActivationCode;