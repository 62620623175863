import ApiService from "./api.service";
import SessionService from "./session.service";

export default {
	async login(payload, option) {
		let loginInfo = await ApiService.post("/login", payload, option)
			.then(res => res.data)
			.catch((err) => {
				if (err) {
					return err.response;
				}
			});
		if(loginInfo.status == 400) return loginInfo;
		const { session, dataUser } = loginInfo;
		SessionService.setSession(session.id,session.idUser, session.email);
		SessionService.setSessionState(dataUser.stateCgu, dataUser.stateOnboarding, dataUser.idSociete, dataUser.idExt);
		return true;
	}
};