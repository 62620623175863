import React from "react";
import PropTypes from "prop-types";
import { getBaseUrl } from "../../../utils/baseUrl";

const FormSubitle = ({ subtitle, link = false, email }) => (
	<h2 style={{ textAlign: "center" }}>
		{subtitle}&nbsp;
		<br/>
		{link &&
		<a href={`${getBaseUrl()}/modules/public/change-password.php?mail=${email}`} >Mot de passe oublié ?</a>
		}
	</h2>
);

FormSubitle.propTypes = {
	subtitle: PropTypes.string,
	link: PropTypes.bool,
	email: PropTypes.string,
};

export default FormSubitle;