import { connect } from "react-redux";

import PrivateRoutes from "../../components/PrivateRoutes/PrivateRoutes";


const mapStateToProps = (state) => ({
	auth: state.user.auth,
});
const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoutes);