import {
	CHECK_IS_LOGGED
} from "../actions/user";

export const initialState = {
};

const conditions = (state = initialState, action = {}) => {
	switch(action.type) {
	case CHECK_IS_LOGGED: {
		return {
			...state,
		};
	}
	default:
		return state;
	}
};

export default conditions;