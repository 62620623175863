import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";

const Wrapper = ({ children, fade, fadeOut, colonneFormSlide, rose, beige, lightPurple }) => (
	<>
		<div id="wrapper" className={`${fade} ${fadeOut} ${colonneFormSlide} ${rose ? "rose" : ""} ${beige ? "beige" : ""} ${lightPurple ? "lightPurple" : ""}`}>
			{children}
		</div>
	</>
);

Wrapper.propTypes = {
	children: PropTypes.node,
	fade: PropTypes.string,
	fadeOut: PropTypes.string,
	colonneFormSlide: PropTypes.string,
	lightPurple: PropTypes.oneOfType([
		PropTypes.bool
	]),
	beige: PropTypes.oneOfType([
		PropTypes.bool
	]),
	rose: PropTypes.oneOfType([
		PropTypes.bool
	]),
};

export default Wrapper;
