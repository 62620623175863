import { connect } from "react-redux";

import Login from "../../../components/LoginTunnel/Login/Login";

import {
	setEmailValue,
	setPasswordValue,
	checkEmail,
	setVerifyPasswordValue,
	login,
	register,
	setErrorMessage,
	setLoginErrorMessage,
	registerEmail,
	checkIsLogged,
	setRememberMe,
} from "../../../actions/user";

const mapStateToProps = (state) => ({
	email: state.user.email,
	password: state.user.password,
	verifiedPassword: state.user.verifiedPassword,
	emailExist: state.user.emailExist,
	emailToCreate: state.user.emailToCreate,
	errorMessage: state.user.errorMessage,
	errorLoginMessage: state.user.errorLoginMessage,
	successMessage: state.user.successMessage,
	redirectionToHome: state.user.redirectionToHome,
	userType: state.user.userType,
	rememberMe: state.user.rememberMe,
	blockedAccount: state.user.blockedAccount
});

const mapDispatchToProps = (dispatch) => ({
	setEmailValue: (value) => {dispatch(setEmailValue(value));},
	setPasswordValue: (value) => {dispatch(setPasswordValue(value));},
	setVerifyPasswordValue: (value) => {dispatch(setVerifyPasswordValue(value));},
	checkEmail: () => {dispatch(checkEmail());},
	login: () => {dispatch(login());},
	register: () => {dispatch(register());},
	registerEmail: () => {dispatch(registerEmail());},
	setErrorMessage: (msg) => {dispatch(setErrorMessage(msg));},
	setLoginErrorMessage: (msg) => {dispatch(setLoginErrorMessage(msg));},
	checkIsLogged: () => {dispatch(checkIsLogged());},
	setRememberMe: (bool) => {dispatch(setRememberMe(bool));}
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);