import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import "./styles.scss";
import emailIcon from "../../../assets/icons/email.svg";
import keyIcon from "../../../assets/icons/key.svg";
import checkIcon from "../../../assets/icons/check.svg";
import errorIcon from "../../../assets/icons/error.svg";
import ItemChecked from "../../../assets/icons/passwordItem.svg";
import ItemUnchecked from "../../../assets/icons/PwItemUnchecked.svg";
import Input from "../../shared/Input/Input";
import Button from "../../shared/Button/Button";
import FormTitle from "../../shared/FormTitle/FormTitle";
import FormSubitle from "../../shared/FormSubtitle/FormSubtitle";
import Alerte from "../../shared/Alerte/Alerte";
import Wrapper from "../../Layout/Wrapper/Wrapper";
import LeftFixImage from "../../Layout/LeftFixImage/LeftFixImage";
import { getBrandValues } from "../../../utils/getBrandValues";
import { destroySession } from "../../../common/session.service";
import { getBaseUrl } from "../../../utils/baseUrl";

const Login = ({
	email,
	password,
	checkEmail,
	verifiedPassword,
	emailToCreate,
	emailExist,
	setEmailValue,
	setPasswordValue,
	setVerifyPasswordValue,
	login,
	register,
	setErrorMessage,
	setLoginErrorMessage,
	errorMessage,
	errorLoginMessage,
	userType,
	successMessage,
	setRememberMe,
	rememberMe,
	blockedAccount
}) => {
// Vérification du nombre d'essai de connexion avec un mauvais mot de passe
	//==== Logique de la vérification du pwd (majuscule, minuscule...)
	//==== permet de cocher les icones "check"
	const [majCheck, setMajCheck] = useState(false);
	const [minCheck, setMinCheck] = useState(false);
	const [numCheck, setNumCheck] = useState(false);
	const [characCheck, setCharacCheck] = useState(false);
	const [heightCheck, setHeightCheck] = useState(false);
	const [enableButton, setEnableButton] = useState(false);
	const [showItemPw, setShowItemPw] = useState(false);
	//=====

	const pwInputRef = useRef();
	useEffect(() => {
		// permet de focus l'input password à son apparition
		if (pwInputRef.current) {
			setTimeout(() => {
				pwInputRef.current.focus();
			}, 200);
		}
	}, [emailExist]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		// const urlParams = new URLSearchParams(window.location.search);
		// const code = urlParams.get("code");
	}, []);
	// Regex pour vérifier le password
	//
	const strongPasswordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^\-_#%\-+€&*(),.?":{}|<>])[A-Za-z\d!@#$%^\-_#\-+%€&*(),.?":{}|<>+]{8,}$/;

	const history = useHistory();

	const handleSubmit = async (e) => {

		if (!emailExist && !emailToCreate) {
			// On reset le localstorage avant de check l'email
			// Pour être sûr de recommencer le processus de connexion de 0
			await destroySession();
			e.preventDefault();
			checkEmail();
		}
		if (emailExist) {
			e.preventDefault();
			errorMessage ? setErrorMessage("") : null;
			errorLoginMessage ? setLoginErrorMessage("") : null;
			login();
			// Le setTimeout permet d'attendre que le middleware login() déclenche les actions
			// permettant de déterminer les différents state : cgu, onboarding, idExt,
			// et dé déclencher le push vers la bonne route
			// C'est optimisable !
			setTimeout(() => {
				const stateCgu = window.localStorage.getItem("stateCgu");
				const stateOnboarding = window.localStorage.getItem("stateOnboarding");
				const idExt = window.localStorage.getItem("idExt");

				if (stateCgu === "0") {
					history.push("/conditions-generales");
				} else if (stateOnboarding === "0" && idExt === "B2C") {
					history.push("/code-activation");
				} else if (stateOnboarding === "0" && idExt !== "B2C") {
					history.push("/onboarding");
				} else if (stateOnboarding === "1") {
					history.push("/redirect");
				} else {
					return null;
				}
			}, 2000);
		}

		if (emailToCreate) {
			e.preventDefault();
			register();
			if (userType === "newUser") {
				setTimeout(() => {
					if (window.localStorage.getItem("idUser")) {
						history.push("/conditions-generales");
					}
				}, 4000);
			}
		}
	};

	const handleEmailValue = (e) => {
		setEmailValue(e.target.value);
	};

	const handlePasswordValue = (e) => {
		const specialChar = /[!\-_#%$€@#\-+$%^&*(),.?":{}|<>]/;
		const number = /\d/;
		const minus = /[a-z]/;
		const maj = /[A-Z]/;
		const passwordRegExp = /.{8,}/;

		// Test s'il existe un caractère spécial dans le mdp
		if (specialChar.test(e.target.value)) {
			setCharacCheck(true);
		} else {
			setCharacCheck(false);
		}
		// Test s'il existe un chiffre dans le mdp
		if (number.test(e.target.value)) {
			setNumCheck(true);
		} else {
			setNumCheck(false);
		}
		// Test s'il existe une miniscule dans le mdp
		if (minus.test(e.target.value)) {
			setMinCheck(true);
		} else {
			setMinCheck(false);
		}
		// Test s'il existe une majuscule dans le mdp
		if (maj.test(e.target.value)) {
			setMajCheck(true);
		} else {
			setMajCheck(false);
		}
		// Test si le mdp contient au minimum 8 caractères
		if (passwordRegExp.test(e.target.value)) {
			setHeightCheck(true);
		} else {
			setHeightCheck(false);
		}
		// Test si toutes les conditions sont réunis
		// Si oui on rend le bouton cliquable
		if (strongPasswordRegExp.test(e.target.value)) {
			setEnableButton(true);
		} else {
			setEnableButton(false);
		}

		setPasswordValue(e.target.value);
	};

	const handleVerifyPasswordValue = (e) => {
		setVerifyPasswordValue(e.target.value);
	};
	const [brandValues, setBrandValues] = useState({});
	useEffect(async () => {
		const fetchedBrandValues = await getBrandValues();
		setBrandValues(fetchedBrandValues);
		document.documentElement.style.setProperty("--primary-color", brandValues.primaryColor);
		document.documentElement.style.setProperty(
			"--background-color",
			brandValues.backgroundColor
		);
		document.documentElement.style.setProperty(
			"--background-color-light",
			brandValues.backgroundColorLight
		);
		document.documentElement.style.setProperty("--text-color", brandValues.textColor);
	}, [brandValues]);

	return (
		<Wrapper>
			<LeftFixImage/>
			<div id="colonneForm" className="fade">
				{!emailToCreate && !emailExist &&
<>
	<img id="logo" src={`${getBaseUrl()}/_LIB/brands/logo-${brandValues.suffix}.png`} alt="prev&care"/>
	<FormTitle title="Accédez à votre espace aidant"/>
	<FormSubitle subtitle="Merci de renseigner votre adresse e-mail pour continuer."/>
</>
				}
				{emailToCreate &&
<>
	<img id="logo" src={`${getBaseUrl()}/_LIB/brands/logo-${brandValues.suffix}.png`} alt="prev&care"/>
	<FormTitle title="Bienvenue, nous ne nous connaissons pas…"/>
	<FormSubitle subtitle="…Créez votre mot de passe dès maintenant et accédez à votre espace !"/>
</>
				}
				{emailExist &&
<>
	<img id="logo" src={`${getBaseUrl()}/_LIB/brands/logo-${brandValues.suffix}.png`} alt="prev&care"/>
	<FormTitle title="Nous sommes heureux de vous revoir !"/>
	<FormSubitle
		subtitle="Entrez votre mot de passe pour vous connecter."
		link={true}
		email={email}
	/>
</>
				}
				<form onSubmit={handleSubmit} method="post" className="form-welcome">
					{!emailToCreate &&
<Input
	label="E-mail"
	src={emailIcon}
	type="email"
	onChange={handleEmailValue}
	email={email}
	value={email}
	valueToActive={email}
	onFocus={() => null}
/>
					}
					{ // CAS OÙ L'EMAIL N'EXISTE PAS
						emailToCreate &&
<>
	<Input
		label="E-mail"
		src={emailIcon}
		type="text"
		onChange={handleEmailValue}
		email={email}
		value={email}
		valueToActive={email}
		onFocus={() => null}
	/>
	<Input
		src={keyIcon}
		type="password"
		label="Mot de passe"
		onChange={handlePasswordValue}
		onFocus={() => setShowItemPw(true)}
		value={password}
		valueToActive={password}
		fade="fade"
		enableEye
	/>
	{showItemPw &&
		<div className="passwordItem fade">
			<div className="itemLine">
				{majCheck ? (
					<img src={ItemChecked} alt={"check"}/>
				) : (
					<img src={ItemUnchecked} alt={"no check"}/>)}
				<p>1 majuscule</p>
				{minCheck ? (<img src={ItemChecked} alt={"check"}/>) : (<img src={ItemUnchecked} alt={"no check"}/>)}
				<p>1 minuscule</p>
			</div>
			<div className="itemLine">
				{characCheck ? (<img src={ItemChecked} alt={"check"}/>) : (<img src={ItemUnchecked} alt={"no check"}/>)}
				<p>1 caractère spécial</p>
				{numCheck ? (<img src={ItemChecked} alt={"check"}/>) : (<img src={ItemUnchecked} alt={"no check"}/>)}
				<p>1 chiffre</p>
			</div>
			<div className="itemLine">
				{heightCheck ? (<img src={ItemChecked} alt={"check"}/>) : (<img src={ItemUnchecked} alt={"no check"}/>)}
				<p>8 caractères</p>
			</div>
		</div>
	}
	<Input
		src={keyIcon}
		type="password"
		label="Confirmer le mot de passe"
		onChange={handleVerifyPasswordValue}
		onFocus={() => null}
		value={verifiedPassword}
		valueToActive={verifiedPassword}
		fade="fade"
	/>
</>
					}
					{emailExist && !blockedAccount &&
<>
	<Input
		src={keyIcon}
		type="password"
		label="Mot de passe"
		onChange={handlePasswordValue}
		onFocus={() => null}
		value={password}
		valueToActive={password}
		fade="fade"
		enableEye
		ref={pwInputRef}
	/>
</>
					}
					{emailExist && blockedAccount &&
						<h2 id={"accountBlocked"}>
							Vous avez atteint le nombre maximal de tentatives de connexion.
							<br />
							Pour des raisons de sécurité, votre compte est temporairement désactivé. Pour le réactiver et mettre à jour votre mot de passe, veuillez nous envoyer un mail à&nbsp;
							<Link
								to="javascript:void(0)"
								onClick={() =>
									(window.location = "caremanagers@prevandcare.com")
								}
							>caremanagers@prevandcare.com
							</Link>
							, ou nous contacter par téléphone au&nbsp;
							<a href="tel:0033187666621">01.87.66.66.21</a>.
						</h2>
					}

					{!emailExist && !emailToCreate &&
<Button color="orange" value="Continuer" disabled={!email}/>
					}
					{emailExist &&
<>
	<div className={"password-container"}>
		<div className="stay-connected-container" onClick={() => {
			!rememberMe ? setRememberMe(true) : setRememberMe(false);
		}}>
			<div className={`stay-connected ${rememberMe ? "active" : ""}`} onClick={() => {
				!rememberMe ? setRememberMe(true) : setRememberMe(false);
			}}>
			</div>
Se souvenir de moi
		</div>
	</div>
	<Button color="orange" value="Vous connecter" disabled={!(password && email)}/>
</>
					}
					{emailToCreate &&
<>
	<div className="password-container">
		<div className="stay-connected-container" onClick={() => {
			!rememberMe ? setRememberMe(true) : setRememberMe(false);
		}}>
			<div className={`stay-connected ${rememberMe ? "active" : ""}`} onClick={() => {
				!rememberMe ? setRememberMe(true) : setRememberMe(false);
			}}>
			</div>
Se souvenir de moi
		</div>
	</div>
	<Button color="orange" value="Validez votre inscription" email={email}
		disabled={!(password && verifiedPassword && enableButton)}/>
</>
					}
				</form>
				{errorMessage &&
<Alerte src={errorIcon} activeClass="error" text={errorMessage}/>
				}
				{errorLoginMessage &&
<Alerte src={errorIcon} activeClass="error" text={errorLoginMessage}/>
				}
				{successMessage &&
<Alerte src={checkIcon} activeClass="success" text={successMessage}/>
				}
			</div>
		</Wrapper>
	);
};

Login.propTypes = {
	setEmailValue: PropTypes.func.isRequired,
	setPasswordValue: PropTypes.func.isRequired,
	setVerifyPasswordValue: PropTypes.func.isRequired,
	setPasswordError: PropTypes.func.isRequired,
	register: PropTypes.func.isRequired,
	login: PropTypes.func.isRequired,
	checkEmail: PropTypes.func.isRequired,
	registerEmail: PropTypes.func.isRequired,
	emailExist: PropTypes.bool.isRequired,
	emailToCreate: PropTypes.bool.isRequired,
	passwordError: PropTypes.bool.isRequired,
	redirectionToHome: PropTypes.bool.isRequired,
	password: PropTypes.string.isRequired,
	verifiedPassword: PropTypes.string.isRequired,
	setErrorMessage: PropTypes.func.isRequired,
	setLoginErrorMessage: PropTypes.func.isRequired,
	errorMessage: PropTypes.string.isRequired,
	errorLoginMessage: PropTypes.string.isRequired,
	successMessage: PropTypes.string.isRequired,
	userType: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	checkIsLogged: PropTypes.func.isRequired,
	setRememberMe: PropTypes.func.isRequired,
	rememberMe: PropTypes.bool.isRequired,
	blockedAccount: PropTypes.bool
};

export default Login;
