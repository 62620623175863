import React from "react";
import PropTypes from "prop-types";

const FormTitle = ({ title }) => (
	<h1>{title}</h1>
);

FormTitle.propTypes = {
	title: PropTypes.string,
};

export default FormTitle;