import { connect } from "react-redux";
import Conditions from "../../../components/LoginTunnel/Conditions/Conditions";
import { checkIsLogged, updateCguState } from "../../../actions/user";
import { updateCaseNoCode } from "../../../actions/activationCode";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
	checkIsLogged: () => {dispatch(checkIsLogged());},
	updateCguState: () => {dispatch(updateCguState());},
	updateCaseNoCode: () => {dispatch(updateCaseNoCode());}
});

export default connect(mapStateToProps,mapDispatchToProps)(Conditions);