import axios from "axios";
import { getApiBaseUrl } from "../utils/baseUrl";

axios.defaults.timeout = 10000;

const ApiService = {
	init() {
		axios.defaults.baseURL = getApiBaseUrl();
	},

	get(ressource, slug ="") {
		return axios.get(`${ressource}/${slug}`).catch(error => {
			throw new Error(error);
		});
	},

	post(ressource, params) {
		return axios.post(`${ressource}/`, params);
	}
};

export default ApiService;