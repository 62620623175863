import Axios from "axios";
import { CHECK_ACTIVATION_CODE, UPDATE_CASE_NO_CODE, setErrorActivation, setSuccessActivation  } from "../actions/activationCode";
import { setSuccessMessage, setRedirection } from "../actions/user";
import { getApiBaseUrl } from "../utils/baseUrl";
import { setErrorApiMessage } from "../actions/apiFlag";

const activationCode = (store) => (next) => (action) => {
	switch(action.type) {
	case CHECK_ACTIVATION_CODE : {
		const state = store.getState();
		const { activationCode } = state;

		const url = `${getApiBaseUrl()}/checkActivation/`;
		const urlUpdate = `${getApiBaseUrl()}/updateUser/`;

		const token = window.localStorage.getItem("id");
		const idUser = window.localStorage.getItem("idUser");
		const email = window.localStorage.getItem("email");

		const payload = {
			"session" : {
				"id" : token,
				"email" : email,
				"idUser" : idUser
			},
			"activation-code" : activationCode.activationCode
		};

		Axios.post(url, payload, { withCredentials: true })
			.then((res) => {
				store.dispatch(setErrorActivation(""));
				store.dispatch(setSuccessActivation(""));

				if (res.data.status === "success") {
					// Si succès, on rattache le user à l'entreprise lié au code d'activation
					const state = store.getState();
					const { user } = state;
					const payload = {
						"session" : {
							"id": window.localStorage.getItem("id"),
							"email" : user.session.email,
							"idUser" : user.session.id,
						},
						"toUpdate": {
							"idExt" : res.data.idExt, // Update de l'idExt en BDD
							"idSociete" : res.data.idSociete // Update de l'idSociete en BDD pour rattché le user à une entreprise
						}
					};
					store.dispatch(setSuccessMessage(""));
					store.dispatch(setSuccessActivation(res.data.message));
					window.localStorage.setItem("success", res.data.message);
					window.localStorage.removeItem("error");
					store.dispatch(setErrorActivation(""));
					Axios.post(urlUpdate, payload, { withCredentials : true }).then(() => null);
				}
				if (res.data.status === "error") {
					// Si erreur, cela veut dire que le code n'existe pas. On envoi un message d'erreur en conséquence
					store.dispatch(setSuccessActivation(""));
					window.localStorage.setItem("error", res.data.message);
					window.localStorage.removeItem("success");
					store.dispatch(setErrorActivation(res.data.message));
					const purgeErrorMessage = () => {
						setTimeout(() => {
							store.dispatch(setErrorActivation(""));
						}, 12000);
					};
					purgeErrorMessage();
				}
			})
			.catch((err) => {
				if (err.response.status === 401) {
					store.dispatch(setRedirection(true));
				}
				if (err) {
					if (err.code === "ECONNABORTED") {
						store.dispatch(setErrorApiMessage(true));
					}
					setTimeout(() => {
						store.dispatch(setErrorApiMessage(false));
					}, 5000);
				}
			});
	}
		next(action);
		break;
	case UPDATE_CASE_NO_CODE: {
		const state = store.getState();
		const { user } = state;
		const url = `${getApiBaseUrl()}/updateUser/`;

		const payload = {
			"session" : {
				"id": window.localStorage.getItem("id"),
				"email" : user.session.email,
				"idUser" : user.session.id,
			},
			"toUpdate": {
				"idExt" : "B2C", // Si le user ne rentre pas de code d'activation, on met B2C par défaut
				"idSociete" : "1" // et l'idSociete à 1
			}
		};
		Axios.post(url, payload, { withCredentials : true })
			.then(() => null)
			.catch((err) => {
				if (err.response.status === 401) {
					store.dispatch(setRedirection(true));
				}
				if (err) {
					if (err.code === "ECONNABORTED") {
						store.dispatch(setErrorApiMessage(true));
					}
					setTimeout(() => {
						store.dispatch(setErrorApiMessage(false));
					}, 5000);
				}
			});
	}
		next(action);
		break;
	default:
		next(action);
	}
};

export default activationCode;