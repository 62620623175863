import React, { useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Login from "../../containers/LoginTunnel/Login";
import OnBoarding from "../../containers/LoginTunnel/OnBoarding";
import ActivationCode from "../../containers/LoginTunnel/ActivationCode";
import Conditions from "../../containers/LoginTunnel/Conditions";
import PropTypes from "prop-types";
import Redirection from "../Redirect/Redirect";
import RedirectionToHome from "../RedirectionToHome/RedirectionToHome";
import PrivateRoutes from "../../containers/PrivatesRoutes";
import { getCookie } from "../../common/cookie.service";
import Alerte from "../shared/Alerte/Alerte";
import Error from "../../assets/icons/error.svg";
import { getBrandValues } from "../../utils/getBrandValues";

const App = ({ setACSource, redirection, loginWithCookie, errorApiMessage, setActivationCode }) => {
	useEffect(() => {
		// Si les cookie de session existent, on authentifie et redirige vers le module Messagerie
		// Lié à la fonctionnalité se souvenir de moi qui, lorsque sélectionné par l'utilisateur,
		// enregistre la session dans les cookies (
		if (getCookie("password") && getCookie("idUser") && getCookie("email"))
			loginWithCookie();
	});


	useEffect(() => {
		// Si le paramètre get option=offres-familles alors on saute l'étape du code d'activation (/code-activation)
		// https://www.prevandcare.com/modules/aidant/login/build/?option=offres-familles
		const urlParams = new URLSearchParams(window.location.search);
		const offresFamilles = urlParams.get("option");
		const codeActivation = urlParams.get("code");
		const codeActivationSource = urlParams.get("source");
		if (offresFamilles === "offres-familles") {
			// L'item du localstorage permet de gérer la condition
			localStorage.setItem("offres-familles", "true");
		}
		setActivationCode(codeActivation);
		setACSource(codeActivationSource);
	}, []);


	useEffect(() => {
		const brandValues = getBrandValues();
		const primaryColor = brandValues?.primaryColor || "#FF6744"; // Couleur par défaut
		const backgroundColor = brandValues?.backgroundColor || "#FFDED4"; // Couleur par défaut
		const textColor = brandValues?.textColor || "#4F0C28"; // Couleur par défaut
		// Injecter la couleur dans une variable CSS globale
		document.documentElement.style.setProperty("--primary-color", primaryColor);
		document.documentElement.style.setProperty("--background-color", backgroundColor);
		document.documentElement.style.setProperty("--text-color", textColor);
	}, []);

	return (
		<div className="App">
			{errorApiMessage &&
<Alerte text={"Une erreur s'est produite. Veuillez réessayer."} activeClass={"error"} src={Error}/>
			}
			{!redirection &&
<Switch>
	<Route exact path="/" component={Login}/>
	<PrivateRoutes exact path="/conditions-generales" component={Conditions}/>
	<PrivateRoutes exact path={"/code-activation"} component={ActivationCode}/>
	<PrivateRoutes exact path={"/onboarding"} component={OnBoarding}/>
	{/*/Redirect permet de rediriger vers le module Messagerie */}
	<Route exact path={"/redirect"} component={Redirection}/>
	{/*/Redirect permet de rediriger vers le composant Login en cas d'echec de checkIsLogged() */}
	<Route exact path={"/redirectionToHome"} component={RedirectionToHome}/>
</Switch>
			}
			{/*La prop redirection passe à true lors d'une réponse 401 de l'API, dans le .catch des appels situé dans les middleswares*/}
			{/* À optimiser puisque seulement quelque route d'API renvoit ce code erreur*/}
			{redirection &&
<>
	<Switch>
		<Route to={"/redirect"} component={Redirection}/>
	</Switch>
</>
			}
		</div>
	);
};

App.propTypes = {
	email: PropTypes.bool,
	// checkIsLogged: PropTypes.func,
	redirection: PropTypes.bool,
	errorApiMessage: PropTypes.bool,
	loginWithCookie: PropTypes.func,
	setActivationCode: PropTypes.func,
	setACSource: PropTypes.func
};


export default withRouter(App);
