import Cookie from "universal-cookie";

const cookie = new Cookie();

export const getCookie = (key, options) => {
	return cookie.get(key, options);
};

export const setCookie = (key, value, options) => {
	cookie.set(key, value, options);
};

export const removeCookie = (key) => {
	cookie.remove(key);
};

export default { getCookie, setCookie, removeCookie };
