import {
	SET_AC_SOURCE,
	SET_ACTIVATION_CODE,
	SET_ERROR_ACTIVATION,
	SET_SHOW_MODAL,
	SET_SUCCESS_ACTIVATION
} from "../actions/activationCode";

export const initialState = {
	activationCode: "",
	errorActivation: "",
	successActivation: "",
	showModal: false,
	ACSource: ""
};

const activationCode = (state = initialState, action = {}) => {
	switch (action.type) {
	// Set la valeur de l'input de l'activation du code
	case SET_ACTIVATION_CODE: {
		return {
			...state,
			activationCode: action.value
		};
	}
	// Action permettant d'afficher la popup d'erreur concernant le code d'activation
	case SET_ERROR_ACTIVATION: {
		return {
			...state,
			errorActivation: action.msg
		};
	}
	// Action permettant d'afficher la popup de succèes concernant le code d'activation
	case SET_SUCCESS_ACTIVATION: {
		return {
			...state,
			successActivation: action.msg
		};
	}
	// Affiche la modal de confirmation pour le code d'activation
	case SET_SHOW_MODAL : {
		return {
			...state,
			showModal: action.bool
		};
	}
	case SET_AC_SOURCE : {
		return {
			...state,
			ACSource: action.string
		};
	}
	default:
		return state;
	}
};

export default activationCode;