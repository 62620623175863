export const hostName = window.location.host;

export const getApiBaseUrl = () => {
	if ((window.location.host === "localhost:3001") || (window.location.host === "localhost:3000")) {
		const baseUrl = "https://aidant.prevandcare.local:8890/api/internal";
		return baseUrl;
	} else {
		const baseUrl = "https://" + window.location.host + "/api/internal";
		return baseUrl;
	}
};

export const getBaseMessagerieUrl = () => {
	if (window.location.host === "localhost:3001") {
		const baseUrl = "http://localhost:3000";
		return baseUrl;
	}
	if (window.location.host === "localhost:3000") {
		const baseUrl = "http://localhost:3001";
		return baseUrl;
	} else {
		const baseUrl = "https://" + window.location.host + "/modules/aidant/messagerie/build/";
		return baseUrl;
	}
};

export const getBaseLoginUrl = () => {
	if (window.location.host === "localhost:3001" || window.location.host === "localhost:3000") {
		const baseUrl = "https://aidant.prevandcare.local:8890/modules/aidant/login/build/";
		return baseUrl;
	} else {
		const baseUrl = "https://" + window.location.host + "/modules/aidant/login/build";
		return baseUrl;
	}
};

export const getBaseUrl = () => {
	if (window.location.host === "localhost:3001" || window.location.host === "localhost:3000") {
		const baseUrl = "https://aidant.prevandcare.local:8890";
		return baseUrl;
	} else {
		const baseUrl = "https://" + window.location.host;
		return baseUrl;
	}
};

// export const baseUrl = "https://" + window.location.hostname + "/api/internal";