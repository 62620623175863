import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import "./styles.scss";
import Wrapper from "../../Layout/Wrapper/Wrapper";
import Button from "../../shared/Button/Button";
import Arrow from "../../../assets/icons/arrow-next.js";
import Alerte from "../../shared/Alerte/Alerte";
import checkIcon from "../../../assets/icons/check.svg";
import Phone from "../../../assets/icons/phone.svg";
import Email from "../../../assets/icons/email.svg";

const OnBoarding = ({ checkIsLogged, updateOnboardingState, setSuccessMessage, successMessage }) => {

	useEffect(checkIsLogged, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [updateOnboardingState]);

	// Utilisé pour l'animation du slide de gauche à droite sur la page onboarding
	const [slideLeft, setSlideLeft] = useState();
	const [slideRight, setSlideRight] = useState("slideRight");

	// Affiche le numéro de téléphone et l'email
	const [help, setHelp] = useState();
	// Permet de passer au boarding suivant
	const [step, setStep] = useState(0);

	const history = useHistory();

	const handleRedirection = () => {
		// Update du onboarding sur 1 en BDD pour indiquer qu'il a été vu complétement par l'utilisateur
		updateOnboardingState();
		window.localStorage.removeItem("stateCgu");
		window.localStorage.removeItem("stateOnboarding");
		setSuccessMessage("Vous allez être redirigé");

		setTimeout(() => {
			history.push("/redirect");
		},2000);
	};

	// switch case like en JSX :
	// {
	// 	{
	// 		'foo': <Foo />,
	// 		'bar': <Bar />
	// 	}[param]
	// }
	// Si le param est à 'foo', on affiche le composant <Foo/>, etc.
	return (
		<>
			{{
				0:
					<Wrapper rose >
						<div id="colonneFormSmallNoShadow" className={"fadeInButton"}>
							<div className={`text ${slideLeft}`}>
								<h2 className="text-orange">Votre espace Aidant </h2>
								<h1>Un espace sécurisé pour vos besoins et ceux de votre proche </h1>
								<p>Bienvenue, votre espace Aidant vous permet de retrouver l’intégralité des documents et informations dont vous aurez besoin tout au long de votre parcours.
									Cet espace est sécurisé et l’intégralité des éléments s’y trouvant sont strictement confidentiels.</p>
								<p>Parce que chaque situation est unique, nous mettons à votre disposition un Care Manager dédié. </p>

								<a href="#" onClick={() => setHelp(true)} className="link text-orange">Besoin d&apos;aide ? Contactez-nous -&gt;</a>
								{help &&
									<ul id="contact-us" className="fade">
										<li><strong>Par mail ou téléphone</strong><br/>du lundi au samedi de 9h à 19h</li>
										<li className="container-contact">
											<a href="tel:0033187666621"><img src={Phone}/>&nbsp;01&nbsp;87&nbsp;66&nbsp;66&nbsp;21</a>
											<a href="mailto:support@prevandcare.com"><img src={Email}/> Mail</a>
										</li>
									</ul>
								}
							</div>

							<div className={`navigation-bottom ${slideLeft === "slideLeft" ? "fadeOutButton" : null}`}>
								<div className="container-button-next">
									<Button color="white" value="Passer" onClick={handleRedirection} />
								</div>
								<span>{step + 1} sur 3</span>
								<div className="container-button-next">
									<Button
										color="orange"
										value="Suivant"
										src
										onClick={() => {
											setSlideLeft("slideLeft");
											setTimeout(() => {
												setStep(1);
												setSlideLeft("");
											}, 1000);
										}}
									>
										<Arrow />
									</Button>
								</div>
							</div>
						</div>
						<div id="colonneImgBigOnBoarding" className={`${slideLeft}`}>
							<div className={`${slideLeft}`}></div>
						</div>
						{successMessage &&
						<Alerte src={checkIcon} activeClass="success" text={successMessage} />
						}
					</Wrapper>,
				1:
				<Wrapper beige>
					<div id="colonneFormSmallNoShadow" className={"fadeInButton"}>
						<div className={`text ${slideRight} ${slideLeft}`}>
							<h2 className="text-orange">Votre Care Manager</h2>
							<h1>Qui est le Care Manager&nbsp;?</h1>
							<p>Le Care Manager est votre partenaire pour trouver et mettre en place des solutions pour vous et votre proche.</p>
							<p>Le savoir-faire du Care Manager est sa capacité d’adaptation et sa force de proposition.</p>
							<p>Concrètement, le Care Manager écoute, conseille, rassure, planifie, coordonne et met en place les solutions adaptées pour apporter de la sérénité au sein de votre famille.</p>

							<a href="#" onClick={() => setHelp(true)} className="link text-orange">Besoin d&apos;aide ? Contactez-nous -&gt;</a>
							{help &&
							<ul id="contact-us" className="fade">
								<li><strong>Par mail ou téléphone</strong><br/>du lundi au samedi de 9h à 19h</li>
								<li className="container-contact">
									<a href="tel:0033187666621"><img src={Phone}/>&nbsp;01&nbsp;87&nbsp;66&nbsp;66&nbsp;21</a>
									<a href="mailto:support@prevandcare.com"><img src={Email}/> Mail</a>
								</li>
							</ul>
							}
						</div>

						<div className={`navigation-bottom fadeInButton ${slideLeft === "slideLeft" ? "fadeOutButton" : null}`}>
							<div className="container-button-next">
								<Button color="white" value="Passer" onClick={handleRedirection} />
							</div>
							<span>{step + 1} sur 3</span>
							<div className="container-button-next">
								<Button color="orange" value="Suivant" src onClick={() => {
									setSlideLeft("slideLeft");
									setSlideRight("");
									setTimeout(() => {
										setStep(2);
										setSlideLeft("");
										setSlideRight("slideRight");
									}, 1000);
								}}
								>
									<Arrow/>
								</Button>
							</div>
						</div>
					</div>
					<div id="colonneImgBigOnBoarding1" className={`${slideRight} ${slideLeft}`}>
						<div className={`${slideRight} ${slideLeft}`}></div>
					</div>
					{successMessage &&
					<Alerte src={checkIcon} activeClass="success" text={successMessage} />
					}
				</Wrapper>,
				2:
				<Wrapper lightPurple>
					<div id="colonneFormSmallNoShadow" className="fadeInButton">
						<div className={`text ${slideRight} ${slideLeft}`}>
							<h2 className="text-orange">Votre messagerie sécurisée</h2>
							<h1>Un espace d&apos;échanges complet</h1>
							<p>Pour contacter un Care Manager, cliquez simplement sur le bouton sur la page suivante.
								Nous vous demanderons quelques informations pour convenir d’un rendez-vous téléphonique à votre convenance. </p>
							<p>Vous pourrez ensuite échanger quand vous le souhaitez avec votre Care Manager via notre messagerie sécurisée, c’est là que vous retrouverez l’ensemble des échanges,
								documents et informations concernant votre accompagnement et celui de votre proche.</p>

							<a href="#" onClick={() => setHelp(true)} className="link text-orange">Besoin d&apos;aide ? Contactez-nous -&gt;</a>
							{help &&
							<ul id="contact-us" className="fade">
								<li><strong>Par mail ou téléphone</strong><br/>du lundi au samedi de 9h à 19h</li>
								<li className="container-contact">
									<a href="tel:0033187666621"><img src={Phone}/>&nbsp;01&nbsp;87&nbsp;66&nbsp;66&nbsp;21</a>
									<a href="mailto:support@prevandcare.com"><img src={Email}/> Mail</a>
								</li>
							</ul>
							}
						</div>

						<div className={`navigation-bottom fadeInButton ${slideLeft === "slideLeft" ? "fadeOutButton" : null}`}>
							<div className="container-button-next">
							</div>
							<span>{step + 1} sur 3</span>
							<div className="container-button-next">
								<Button color="orange" value="Passer" src={Arrow} onClick={handleRedirection} />
							</div>
						</div>
					</div>
					<div id="colonneImgBigOnBoarding2" className={`${slideRight} ${slideLeft}`}>
						<div className={`${slideRight} ${slideLeft}`}></div>
					</div>
					{successMessage &&
					<Alerte src={checkIcon} activeClass="success" text={successMessage} />
					}
				</Wrapper>
			}[step]}
		</>
	);
};

OnBoarding.propTypes = {
	checkIsLogged: PropTypes.func,
	updateOnboardingState: PropTypes.func,
	setSuccessMessage: PropTypes.func,
	successMessage: PropTypes.string
};

export default OnBoarding;