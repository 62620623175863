export const setSession = (id, idUser, email) => {
	window.localStorage.setItem("id", id);
	window.localStorage.setItem("idUser", idUser);
	window.localStorage.setItem("email", email);
};

export const setSessionState = (stateCgu, stateOnboarding, idSociete, idExt) => {
	window.localStorage.setItem("stateCgu", stateCgu);
	window.localStorage.setItem("stateOnboarding", stateOnboarding);
	window.localStorage.setItem("idSociete", idSociete);
	window.localStorage.setItem("idExt", idExt);
};


export const getSession = () => {
	return{ "session" :  { "email": window.localStorage.getItem("email") , "id": window.localStorage.getItem("id"), "idUser": window.localStorage.getItem("idUser") } };
};

export const destroySession = () => {
	window.localStorage.removeItem("token");
	window.localStorage.removeItem("user");
	window.localStorage.clear();
};

export default { getSession,  setSession, destroySession, setSessionState };