import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";
import Button from "../../shared/Button/Button";
import { useHistory } from "react-router-dom";

const Modal = ({ showModal, setShowModal, updateCaseNoCode, setSuccessMessage }) => {

	const stateOnboarding = window.localStorage.getItem("stateOnboarding");

	const history = useHistory();

	const handleSubmit = (e) => {
		e.preventDefault();
		// Cas où le user n'a pas de code d'activation
		updateCaseNoCode();
		if (stateOnboarding === "0") {
			setSuccessMessage("");
			// Si le user n'est pas jamais passer par le onboarding (0) on le redirige dessus
			history.push("/onboarding");
		} else {
			setSuccessMessage("");
			history.push("/redirect");
		}
	};

	const handleReject = (e) => {
		e.preventDefault();
		setShowModal(false);
	};

	return (
		<>
			<div className="content">
				<h2>Attention…</h2>
				<p>…Vous n&apos;avez pas renseigné de code d’activation. Souhaitez-vous continuer ?</p>
				<div className="containerButton">
					<Button value={"Oui"} color={"orange"} onClick={handleSubmit}/>
					<Button value={"Non"} color={"orange"} onClick={handleReject}/>
				</div>
			</div>
			<div className="modalBackground" onClick={() => showModal ? setShowModal(false) : null}  ></div>
		</>
	);
};

Modal.propTypes = {
	setShowModal: PropTypes.func,
	checkActivationCode: PropTypes.func,
	showModal: PropTypes.bool,
	updateCaseNoCode: PropTypes.func,
	setSuccessMessage: PropTypes.func,
	activationCode: PropTypes.string,
};

export default Modal;
