export const SET_ERROR_ACTIVATION = "SET_ERROR_ACTIVATION";
export const SET_SUCCESS_ACTIVATION = "SET_SUCCESS_ACTIVATION";
export const SET_ACTIVATION_CODE = "SET_ACTIVATION_CODE";
export const CHECK_ACTIVATION_CODE = "CHECK_ACTIVATION_CODE";
export const UPDATE_CASE_NO_CODE = "UPDATE_CASE_NO_CODE";
export const SET_SHOW_MODAL = "SET_SHOW_MODAL";
export const SET_AC_SOURCE = "SET_AC_SOURCE";


export const setActivationCode = (value) => ({
	type: SET_ACTIVATION_CODE,
	value
});

export const checkActivationCode = () => ({
	type: CHECK_ACTIVATION_CODE,
});

export const updateCaseNoCode = () => ({
	type: UPDATE_CASE_NO_CODE,
});

export const setErrorActivation = (msg) => ({
	type: SET_ERROR_ACTIVATION,
	msg
});

export const setSuccessActivation = (msg) => ({
	type: SET_SUCCESS_ACTIVATION,
	msg
});

export const setShowModal = (bool) => ({
	type: SET_SHOW_MODAL,
	bool
});

export const setACSource = (string) => ({
	type: SET_AC_SOURCE,
	string
});
