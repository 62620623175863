import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const Alerte = ({ activeClass, text, src }) => {
	return (
		<div className={`container-messages ${text ? "display" : "" }`}>
			<div className={`message ${activeClass}`}><img src={src}/>{text}</div>
		</div>
	);
};

Alerte.propTypes = {
	activeClass: PropTypes.string,
	text: PropTypes.string,
	src: PropTypes.string,
};



export default Alerte;