import React from "react";
import "./styles.scss";
import contactImg from "../../../assets/img/contactimg.png";
const LeftFixImage = () => (
	<div id="colonneImg" >
		<div title="illustration">
			<img src={contactImg}/>
			<h1>Prenez soin de votre proche fragile avec un expert dédié à vos côtés.</h1>
			<h2>Rencontrez&nbsp;votre&nbsp;Care&nbsp;Manager&nbsp;!</h2>
			<p>Que votre proche soit fragilisé par l&apos;âge, la maladie ou le handicap, nous sommes là pour vous aider.</p>
		</div>
	</div>
);

export default LeftFixImage;