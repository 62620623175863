import React, { useEffect } from "react";
import { getBaseMessagerieUrl } from "../../utils/baseUrl";

const Redirection = () => {
	const id = window.localStorage.getItem("id");
	const idUser = window.localStorage.getItem("idUser");
	const email = window.localStorage.getItem("email");
	useEffect(() => {
		if (id && idUser && email) {
			window.localStorage.clear();
			window.location.href = `${getBaseMessagerieUrl()}?id=${id}&idUser=${idUser}&email=${email}`;
		}
	}, []);
	return (
		<div>
		</div>
	);
};

export default Redirection;