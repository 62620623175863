import { connect } from "react-redux";
import OnBoarding from "../../../components/LoginTunnel/OnBoarding/OnBoarding";
import { checkIsLogged, setSuccessMessage, updateOnboardingState } from "../../../actions/user";

const mapStateToProps = (state) => ({
	successMessage: state.user.successMessage
});

const mapDispatchToProps = (dispatch) => ({
	checkIsLogged: () => {dispatch(checkIsLogged());},
	updateOnboardingState: () => {dispatch(updateOnboardingState());},
	setSuccessMessage: (msg) => {dispatch(setSuccessMessage(msg));}
});

export default connect(mapStateToProps,mapDispatchToProps)(OnBoarding);