import { combineReducers } from "redux";

import user from "./user";
import activationCode from "./activationCode";
import conditions from "./conditions";
import apiFlag from "./apiFlag";

// Placer les différents reducer à utiliser
export default combineReducers({
	user,
	activationCode,
	conditions,
	apiFlag,
});