import { connect } from "react-redux";

import ActivationCode from "../../../components/LoginTunnel/ActivationCode/ActivationCode";
import {
	checkActivationCode,
	setACSource,
	setActivationCode,
	setShowModal,
	updateCaseNoCode
} from "../../../actions/activationCode";
import { checkIsLogged, setSuccessMessage } from "../../../actions/user";

const mapStateToProps = (state) => ({
	activationCode: state.activationCode.activationCode,
	errorActivation: state.activationCode.errorActivation,
	successActivation: state.activationCode.successActivation,
	dataUser: state.user.dataUser,
	session: state.user.session,
	showModal: state.activationCode.showModal,
	ACSource: state.activationCode.ACSource
});

const mapDispatchToProps = (dispatch) => ({
	setActivationCode: (value) => {
		dispatch(setActivationCode(value));
	},
	checkActivationCode: () => {
		dispatch(checkActivationCode());
	},
	checkIsLogged: () => {
		dispatch(checkIsLogged());
	},
	updateCaseNoCode: () => {
		dispatch(updateCaseNoCode());
	},
	setSuccessMessage: () => {
		dispatch(setSuccessMessage());
	},
	setShowModal: (bool) => {
		dispatch(setShowModal(bool));
	},
	setACSource: (string) => {
		dispatch(setACSource(string));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivationCode);