import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Wrapper from "../../Layout/Wrapper/Wrapper";
import LeftFixImage from "../../Layout/LeftFixImage/LeftFixImage";

import "./styles.scss";
import { useHistory } from "react-router-dom";

const Conditions = ({ checkIsLogged, updateCguState, updateCaseNoCode }) => {

	useEffect(() => {
		checkIsLogged();
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const [bottom, setBottom] = useState(false);
	const history = useHistory();

	const handleScroll = (e) => {
		const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
		if (bottom) {
			setBottom(true);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// Update le champ du state en BDD
		updateCguState();
		const offresFamilles = localStorage.getItem("offres-familles");
		if (offresFamilles == "true") {
			history.push("/onBoarding");
			updateCaseNoCode();
		} else {
			history.push(`${process.env.PUBLIC_URL}/code-activation`);
		}
	};

	return (
		<Wrapper>
			<LeftFixImage/>
			<form onSubmit={handleSubmit} id="colonneForm" className="fade">
				<h1 className="centered title-cgu">Conditions générales<br/>d&apos;utilisation & données personnelles
				</h1>
				<h2>Pour continuer, nous vous remercions de lire et d&apos;accepter nos CGU</h2>

				<div className="container-cgu fade">
					<div className="bloc-cgu" onScroll={handleScroll}>
						<div className="pink medium biggerText marginBottom">PREAMBULE</div>
                        Prev & Care est une société par actions simplifiée au capital de 30.910 euros, dont le siège
                        social est situé 128 RUE LA BOÉTIE 75008 Paris, immatriculée au Registre du Commerce et des
                        Sociétés de Paris sous le numéro 821 031 150, représentée par Monsieur Guillaume Staub, agissant
                        en qualité de Président («&nbsp;Prev & Care&nbsp;»).<br/><br/>

                        Le numéro de TVA intracommunautaire de Prev & Care est le FR10821031150<br/><br/>

                        Prev & Care peut être joint aux coordonnées suivantes&nbsp;<br/><br/>

                        - par courrier postal, au 128 RUE LA BOÉTIE 75008, Paris<br/>
                        - par téléphone&nbsp;: 01 75 50 85 09<br/>
                        - par email&nbsp;: contact@prevandcare.com<br/><br/>

                        Prev & Care exerce une activité de prestataire de services familiaux et à la personne souscrits
                        par une clientèle d’entreprises, de mutuelles, de collectivités territoriales et/ou d’entités
                        publiques (les «&nbsp;Clients&nbsp;»), pour le bénéfice de leurs salariés, agents, adhérents ou
                        usagers souhaitant disposer d’un appui ou d’une assistance de Prev & Care dans le cadre de leurs
                        problématiques personnelles et familiales (perte d’autonomie d’un proche, parentalité, équilibre
                        personnel, etc.) (les «&nbsp;Utilisateurs&nbsp;»).<br/><br/>

                        Les Utilisateurs ont accès à une offre complète de services rendus par Prev & Care dans le
                        domaine des services familiaux et à la personne (les «&nbsp;Services&nbsp;»),
                        notamment&nbsp;: <br/><br/>

                        - un accès à la plateforme internet de Prev & Care (adresse URL http://www.prevandcare.com (la
                        «&nbsp;Plateforme&nbsp;»))<br/><br/>

                        - des services d’assistance et de conciergerie permettant la prise en charge et gestion de leurs
                        besoins par des partenaires sélectionnés dans le domaine des services familiaux et à la personne
                        (les «&nbsp;Services de Conciergerie&nbsp;»)&nbsp;;<br/><br/>

                        - l’organisation d’évènements, de conférences et/ou d’ateliers sur des thématiques
                        médico-sociales et de bien-être (les «&nbsp;Ateliers&nbsp;»). <br/><br/>

                        Les présentes conditions générales d’utilisation (les «&nbsp;Conditions Générales&nbsp;») ont
                        pour objet de définir et de régir les rapports contractuels entre Prev & Care et tout
                        Utilisateur bénéficiant des Services. <br/><br/>

                        L’utilisation des Services est conditionnée à la création d’un compte sur la Plateforme et à
                        l’acceptation préalable sans restriction ni réserve des présentes Conditions
                        Générales.<br/><br/>

                        Les mentions légales et informations relatives au traitement des données personnelles, aux
                        cookies et liens hypertextes figurent dans la rubrique «&nbsp;Mentions Légales&nbsp;et Données
                        Personnelles » sur la Plateforme.
                        Un délégué à la protection des données personnelles chargé de veiller à la conformité à la
                        réglementation et de répondre à toutes demandes d’information et/ou conseil relatives aux
                        données personnelles peut être joint à l’adresse suivante&nbsp;: dpo@prevandcare.com.<br/><br/>


						<div className="pink medium biggerText marginBottom">1. DEFINITIONS</div>

                        « Atelier» : a le sens qui lui est attribué dans le préambule<br/><br/>

                        « Client » : a le sens qui lui est attribué dans le préambule.<br/><br/>

                        « Conditions Générales » : a le sens qui lui est attribué dans le préambule.<br/><br/>

                        « Contrat Cadre » : désigne le contrat cadre de prestations conclu entre Prev & Care et le
                        Client permettant
                        aux Utilisateurs de bénéficier des Services fournis par Prev & Care.<br/><br/>

                        « Plan d’Actions » : a le sens qui lui est attribué à l’article 2.2.<br/><br/>

                        « Plateforme » : a le sens qui lui est attribué dans le préambule.<br/><br/>

                        « Prev & Care » : a le sens qui lui est attribué dans le préambule.<br/><br/>

                        « Services » : désigne l’ensemble des services susceptibles d’être rendus par Prev & Care aux
                        Utilisateurs
                        dans le domaine des services familiaux et à la personne, tels qu’ils sont décrits dans le
                        préambule.<br/><br/>

                        « Services de Conciergerie » : a le sens qui lui est attribué dans le préambule. <br/><br/>

                        « Utilisateur » : désigne toute personne physique bénéficiaire des Services fournis par Prev &
                        Care, en tant
                        que salarié, agent, adhérent ou usager d’un Client ayant conclu un Contrat Cadre.<br/><br/>


						<div className="pink medium biggerText marginBottom">2. DeSCRIPTION DES SERVICES</div>

						<div className="pink medium intermediateText marginBottom">2.1. Plateforme</div>

                        Les Services comprennent un accès des Utilisateurs à la Plateforme, disponible en mode SaaS («
                        Software As A
                        Service »), et permettant d’accéder à un certain nombre de ressources et fonctionnalités par
                        l’intermédiaire
                        d’un espace personnel comportant un tableau de bord : prise de rendez-vous avec des conseillers
                        familiaux,
                        newsletters mensuelles, inscription aux évènements et ateliers, ressources éditoriales et
                        documentaires,
                        bibliothèque de fiches techniques et pratiques, espace personnel d’archivage et
                        d’échange.<br/><br/>

                        La Plateforme est régulièrement enrichie et mise à jour, sur une base constante et régulière, de
                        contenus
                        ciblés et pertinents relatifs aux problématiques personnelles et familiales rencontrées par les
                        Utilisateurs, comprenant notamment des newsletters, des fiches pratiques, des brochures et des
                        ressources
                        documentaires.<br/><br/>

						<div className="pink medium intermediateText marginBottom">2.2. Services de Conciergerie</div>

                        Selon les termes du Contrat Cadre conclu avec le Client, les Utilisateurs peuvent avoir accès
                        aux Services
                        de Conciergerie matérialisés par une assistance donnant accès à des conseillers familiaux dédiés
                        susceptibles d’informer les Utilisateurs, d’assurer leur prise en charge, l’évaluation de leur
                        situation et
                        leurs besoins, et la mise en œuvre, la coordination et le suivi de plans d’actions personnalisés
                        et adaptés
                        à leurs problématiques de nature personnelle et familiale (les « Plans d’Actions »).<br/><br/>

                        Les Services de Conciergerie de Prev & Care sont décrits plus précisément sur la
                        Plateforme. <br/><br/>

                        Ils sont disponibles sur la Plateforme et/ou via une assistance téléphonique pilotée par des
                        conseillers
                        familiaux. <br/><br/>

                        La mise en place d’une permanence physique animée par un conseiller familial dédié sur le(s)
                        site(s) du
                        Client peut également intervenir en fonction des stipulations du Contrat Cadre et est le cas
                        échéant
                        précisée aux Utilisateurs sur la Plateforme. <br/><br/>

                        Les conseillers familiaux de Prev & Care ont notamment pour mission : <br/><br/>

                        - l’information et l’évaluation préalable des besoins, le conseil et l’orientation des
                        Utilisateurs ; <br/>
                        - le cas échéant, l’assistance dans la définition des Plans d’Actions ; <br/>
                        - la mise en relation avec des partenaires qualifiés et sélectionnés par Prev & Care sur la base
                        de critères
                        prédéfinis avec le Client aux fins d’assurer la mise en œuvre des Plans d’Actions (les «
                        Partenaires »)
                        ; <br/>
                        - la coordination avec les Partenaires et le suivi des Plans d’Actions mis en place auprès des
                        Utilisateurs.<br/><br/>

						<div className="pink medium intermediateText marginBottom">2.3. Ateliers</div>

                        Les Ateliers auxquels les Utilisateurs peuvent participer sont organisés en coordination avec le
                        Client dans
                        ses établissements et locaux, sur des thématiques médico-sociales et de bien être et selon un
                        calendrier
                        définis d’un commun accord avec le Client.<br/><br/>

                        Les Utilisateurs peuvent consulter le programme et s’y inscrire par l’intermédiaire de la
                        Plateforme. <br/><br/>


						<div className="pink medium biggerText marginBottom">3. ACCES AUX SERVICES</div>

						<div className="pink medium intermediateText marginBottom">3.1. Accès aux Services et création
                            d’un compte
                            sur la Plateforme
						</div>

                        Toute Utilisateur désirant bénéficier des Services de Prev & Care devra remplir les conditions
                        suivantes
                        :<br/><br/>

                        - disposer de la pleine capacité ; <br/>
                        - être éligible pour bénéficier des Services aux termes du Contrat Cadre conclu avec le Client
                        auquel il est
                        rattaché ; <br/>
                        - créer gratuitement un compte sur la Plateforme en utilisant l’extension mail de son adresse
                        électronique
                        professionnelle utilisée chez le Client ;<br/>
                        - renseigner les différents champs du formulaire de création d’un compte
                        (nom/prénom/dénomination sociale,
                        adresse électronique, numéro de téléphone, etc.) ;<br/>
                        - confirmer son acceptation des Conditions Générales ; <br/>
                        - confirmer son inscription. <br/><br/>

                        Par ailleurs, pour bénéficier des Services de Conciergerie de Prev & Care, tout Utilisateur
                        pourra être tenu
                        de communiquer certains documents et justificatifs complémentaires, et autorisera le cas échéant
                        Prev & Care
                        à ce qu’ils soient transmis à des Partenaires.<br/><br/>

                        L’accès à la Plateforme est possible à partir d’un ordinateur, d’un smartphone ou d’une
                        tablette, et
                        nécessite une connexion à internet haut débit et/ou à internet mobile. <br/><br/>

                        Les Utilisateurs et le Client font leur affaire personnelle de la mise en place des moyens
                        informatiques et
                        de télécommunication permettant l’accès à la Plateforme.<br/><br/>

                        Le Client devra mettre à la disposition de chaque Utilisateur une adresse professionnelle ainsi
                        que le
                        matériel, les systèmes informatiques, les logiciels systèmes et une connexion internet conformes
                        pour
                        l’utilisation de la Plateforme.<br/><br/>


						<div className="pink medium intermediateText marginBottom">3.2. Confirmation d’inscription</div>

                        Lors de la création d’un compte avec son adresse électronique professionnelle, l’Utilisateur est
                        invité à
                        choisir un mot de passe, qui constitue la garantie de la confidentialité des informations qui
                        seront
                        contenues dans son compte.<br/><br/>

                        Aux fins de validation de l’inscription, Prev & Care adresse à l’Utilisateur un courrier
                        électronique de
                        confirmation à l’adresse email renseignée par ce dernier. <br/><br/>

                        L’Utilisateur procède alors à la validation de son compte en cliquant sur le lien hypertexte
                        prévu à cet
                        effet dans le courrier électronique de confirmation.<br/><br/>

                        L’activation effective du profil de l’Utilisateur n’a lieu qu’à compter du jour où l’ensemble
                        des conditions
                        d’inscription visées à l’article 3.1 sont remplies. <br/><br/>

                        Chaque Utilisateur garantit la sincérité et l&apos;exactitude des informations fournies à Prev &
                        Care pour les
                        besoins de son inscription, s&apos;engage à lui notifier toute modification ultérieure et
                        garantit que lesdites
                        informations ne portent pas atteinte aux droits de tiers. <br/><br/>

                        L’Utilisateur peut modifier ses informations, identifiants et mot de passe à partir de son
                        compte sur la
                        Plateforme.<br/><br/>

                        L’Utilisateur s’engage à ne pas divulguer ou transférer son compte, ses identifiants et mots de
                        passe et est
                        seul responsable de leur utilisation jusqu’à leur désactivation. Il doit informer immédiatement
                        Prev & Care
                        de toute perte ou utilisation non autorisée de son compte.<br/><br/>

                        Prev & Care se réserve le droit de supprimer le compte de tout Utilisateur qui aurait fourni des
                        informations erronées.<br/><br/>


						<div className="pink medium biggerText marginBottom">4. GRATUITE DES SERVICES</div>

                        Les Services proposés par Prev & Care ont été souscrits par le Client pour le bénéfice des
                        Utilisateurs aux
                        termes du Contrat Cadre : ils sont ainsi accessibles gratuitement aux Utilisateurs. <br/><br/>

                        Dans le cadre des Services de Conciergerie et pour la mise en œuvre de Plans d’Actions, Prev &
                        Care peut
                        être amenée à présenter et/ou conseiller aux Utilisateurs les services spécifiques d’un
                        Partenaire
                        préalablement sélectionné. <br/><br/>

                        Les services fournis par les Partenaires sont distincts de ceux fournis par Prev & Care : leur
                        rémunération
                        et leurs autres termes et conditions sont fixées de manière autonome entre les Utilisateurs et
                        les
                        Partenaires dans une documentation contractuelle distincte.<br/><br/>

						<div className="pink medium biggerText marginBottom">5. OBLIGATIONS DE PREV & CARE</div>

                        Prev & Care déclare (i) avoir la pleine capacité pour fournir les Services, (ii) avoir les
                        compétences, le
                        savoir-faire, l’expérience et les moyens nécessaires pour l’exécution de ses obligations, (iii)
                        que la
                        fourniture des Services ne violera aucun droit de tiers, et (iv) s’engager à respecter les lois
                        et
                        règlements qui lui sont applicables en relation avec l’exécution du présent Contrat.<br/><br/>

                        Prev & Care fournira les Services de bonne foi et mettra en œuvre tous les soins et moyens
                        requis par la
                        diligence professionnelle et les usages de la place à l’exécution de ses obligations, dans le
                        respect des
                        droits de tiers et des dispositions législatives et réglementaires.<br/><br/>

                        Prev & Care s’efforce d’assurer l’accès et le bon fonctionnement de la Plateforme vingt-quatre
                        (24) heures
                        sur vingt-quatre (24), sept (7) jours sur sept (7). <br/><br/>

                        Prev & Care s’efforce également d’assurer la disponibilité des Services de Conciergerie par
                        téléphone six
                        (6) jours sur sept (7) de 9h00 à 19h00, heures françaises. <br/><br/>

                        Prev & Care est tenue d’une obligation de moyen de conseil auprès des Utilisateurs et leur
                        fournira
                        notamment tous les éléments suffisants dans le cadre de la définition et mise en œuvre de Plans
                        d’Actions
                        pour leur permettre de prendre, en toute connaissance de cause, les décisions qui
                        s’imposent. <br/><br/>

                        Prev & Care ne peut exclure que l’accès et le fonctionnement des Services soient interrompus
                        notamment en
                        cas de force majeure, de dysfonctionnements des équipements ou du réseau internet du Client
                        et/ou des
                        Utilisateurs, de défaillance ou dégradation mécanique, électronique ou de communication (y
                        compris les
                        bruits de ligne et perte de réseau), d’interruption de fourniture d’électricité, d’utilisation
                        anormale,
                        illicite ou frauduleuse des Services par un Utilisateur ou un tiers, de décision des autorités
                        compétentes,
                        ou pour tout autre motif. <br/><br/>

                        Prev & Care se réserve également le droit d&apos;apporter aux Services toutes les modifications
                        et améliorations
                        de son choix liées à l&apos;évolution technique ou au bon fonctionnement. Les interruptions
                        générales et
                        temporaires des Services seront, dans la mesure du possible, notifiées via la Plateforme avant
                        qu’elles
                        n’interviennent, sauf lorsque ces interruptions ont un caractère d’urgence. <br/><br/>

                        Enfin, Prev & Care édite sur la Plateforme des contenus ciblés et pertinents relatifs aux
                        problématiques
                        personnelles et familiales rencontrées par les Utilisateurs mais est également susceptible
                        d’héberger des
                        contenus mis en ligne par les Utilisateurs, à l’égard desquels Prev & Care agit en tant que
                        prestataire
                        technique et n’effectue aucun contrôle a priori quant à leur licéité, exactitude, qualité ou
                        sincérité. <br/><br/>

                        Prev & Care s’engage à retirer promptement tout contenu manifestement illicite qui serait porté
                        à sa
                        connaissance, notamment lorsque l’existence d’un tel contenu lui aura été notifiée par un
                        Utilisateur dans
                        les conditions prévues par la réglementation applicable.<br/><br/>


						<div className="pink medium biggerText marginBottom">6. OBLIGATIONS DES UTILISATEURS</div>

                        Chaque Utilisateur s&apos;engage à accéder à la Plateforme et utiliser les Services
                        personnellement et de manière
                        loyale, conformément aux lois en vigueur et aux présentes Conditions Générales, sans pouvoir
                        autoriser un
                        tiers quelconque à les utiliser à sa place.<br/><br/>

                        Les contenus, documents et informations mis en ligne sur la Plateforme et/ou communiqués par les
                        Utilisateurs devront contenir des informations exactes, sincères et loyales et seront publiés ou
                        transmis
                        sous leur seule responsabilité. <br/><br/>

                        En cas de relations contractuelles initiées par un Utilisateur avec un Partenaire présenté par
                        Prev & Care,
                        l’Utilisateur s&apos;engage à conclure sous sa responsabilité la documentation nécessaire avec
                        le Partenaire et à
                        mettre en œuvre toutes les diligences requises pour l’exécution de ses obligations.<br/><br/>

                        Plus généralement, chaque Utilisateur s’engage à :<br/><br/>

                        - fournir des informations et données complètes, sincères et exactes à Prev & Care et aux
                        Partenaires le cas
                        échéant ;<br/><br/>

                        - ne pas diffuser de contenu illicite ou ayant pour effet de diminuer, de désorganiser, de
                        ralentir ou
                        d’interrompre la circulation normale des données sur la Plateforme ; <br/><br/>

                        - signaler immédiatement au Client et à Prev & Care toute difficulté, réserve ou tout litige
                        survenu au
                        cours de l’exécution des Services ou toute utilisation anormale, abusive ou frauduleuse des
                        Services dont il
                        aurait connaissance. <br/><br/>

                        Chaque Utilisateur déclare par ailleurs :<br/><br/>

                        - être informé des contraintes et des limites des réseaux Internet et ne pourra en aucun cas
                        rechercher la
                        responsabilité de Prev & Care au titre de dysfonctionnements dans l&apos;accès à la Plateforme,
                        des vitesses
                        d&apos;ouverture et de consultation des pages, de l&apos;inaccessibilité temporaire ou
                        définitive de la Plateforme ou
                        de l&apos;utilisation illicite ou frauduleuse par des Utilisateurs ou des tiers de la
                        Plateforme&nbsp;; <br/><br/>

                        - être responsable des contenus et informations importés, stockés et/ou publiés sur la
                        Plateforme et
                        s’engager à n’employer aucune mesure technique de nature à permettre le contournement des
                        mesures techniques
                        de protection mises en place par Prev & Care en vue d’éviter toute utilisation illicite ou
                        frauduleuse de la
                        Plateforme. ; <br/><br/>

                        - prendre sous sa seule responsabilité l’ensemble des mesures de nature à assurer l’intégrité et
                        la
                        sauvegarde de l’ensemble de ses données, fichiers et documents.
						<br/><br/>
                        Dans l&apos;hypothèse où un Utilisateur serait responsable d&apos;une infraction à la
                        législation en vigueur ou d&apos;une
                        atteinte aux droits des tiers, Prev & Care se réserve le droit de fournir, à la demande de toute
                        autorité
                        légitime (juridiction, autorité administrative, services de police), tous renseignements
                        permettant ou
                        facilitant l&apos;identification de l’Utilisateur contrevenant. <br/><br/>


						<div className="pink medium biggerText marginBottom">7. RESPONSABILITE</div>

                        Le rôle de Prev & Care se limite, en qualité de prestataire, à fournir les Services bénéficiant
                        aux Utilisateurs, sans intervenir d’aucune sorte dans les relations entre le Client et les
                        Utilisateurs, ni dans les relations ayant pu s’établir entre les Utilisateurs et des
                        Partenaires. <br/><br/>

                        La responsabilité de Prev & Care est définie d’un commun accord avec le Client au sein du
                        Contrat Cadre. <br/><br/>

                        Prev & Care étant tenue d’une obligation de moyens, sa responsabilité ne peut être engagée qu’en
                        cas de manquement à ses obligations propres ayant causé un préjudice matériel, direct et
                        certain. <br/><br/>

                        Prev & Care décline toute responsabilité pour tous dommages indirects et/ou immatériels et/ou
                        imprévisibles qui pourraient être subis par le Client, directement ou indirectement, à raison de
                        l’utilisation par les Utilisateurs de la Plateforme ou de la fourniture de Services par Prev &
                        Care.
						<br/><br/>
                        Prev & Care n’encourt aucune responsabilité et ne fournit aucune garantie en cas de dommage de
                        quelque nature que ce soit subi dans les cas suivants&nbsp;: <br/><br/>

                        - en cas d’inadéquation des Services aux besoins&nbsp;et exigences non standard ;<br/><br/>

                        - absence de fiabilité, exactitude, sincérité, pertinence et exhaustivité des informations, du
                        contenu et des données transmises à Prev & Care ; <br/><br/>

                        - détournement, intrusion frauduleuse, malveillante ou non autorisée, piratage dextraction ou
                        altération non autorisée ou contamination par des virus informatiques des données ou du matériel
                        du Client et/ou des Utilisateurs ; <br/><br/>

                        - manquement ou inobservation par les Utilisateurs aux obligations prévues aux présentes, aux
                        consignes dinstallation, de paramétrage et d’utilisation de la Plateforme ou aux dispositions
                        légales ou réglementaires applicables&nbsp;; <br/><br/>

                        - dans le cas où les fonctionnalités de la Plateforme savèreraient incompatibles avec certains
                        équipements et/ou fonctionnalités du matériel informatique du Client et/ou d’un
                        Utilisateur&nbsp;; <br/><br/>

                        - en cas de perte éventuelle des informations accessibles dans l’espace personnel de
                        l’Utilisateur, celui-ci devant en sauvegarder une copie et ne pouvant prétendre à aucun
                        dédommagement à ce titre&nbsp;;
						<br/><br/>
                        - utilisation des résultats des Services pour un objet ou une finalité différents de ceux pour
                        lesquels Prev & Care est intervenue, mise en œuvre erronée des recommandations et des Plans
                        d’Actions proposés, ou absence de prise en compte des réserves émises ; <br/><br/>

                        - force majeure ou cas fortuit au sens de l’article 1218 du Code Civil, y compris, mais sans y
                        être limités, catastrophes, incendies, grève interne ou externe, défaillance ou pannes internes
                        ou externes de la Plateforme, et dune manière générale tout évènement irrésistible et
                        imprévisible ne permettant pas la fourniture des Services dans des conditions
                        adéquates&nbsp;;<br/><br/>

                        - dans le cas où l’inexécution de ses obligations serait imputable au Client, ou aux
                        Utilisateurs&nbsp;; <br/><br/>

                        - fourniture ou mise à disposition de solutions et de services par un Partenaire de Prev & Care
                        ayant conclu un contrat distinct avec un Utilisateur, seule la responsabilité de ce Partenaire
                        étant alors susceptible d’être engagée.<br/><br/>

                        Prev & Care ne garantit pas que les Services, soumis à une recherche constante pour en améliorer
                        notamment la performance et le progrès, seront totalement exempts d’erreurs, de vices ou
                        défauts. <br/><br/>

                        Concernant les relations avec les Partenaires, il est rappelé que l’activité de Prev & Care se
                        limite à présenter et mettre en relation l’Utilisateur et un Prestataire préalablement
                        sélectionné, en vue de la mise en œuvre d’un Plan d’Action préalablement défini. Prev & Care
                        n’est donc partie au contrat éventuellement conclu entre un Utilisateur et un Partenaire et ne
                        garantit pas le suivi ou la qualité des prestations fournies. <br/><br/>

                        Toute réclamations ou contestations d’un Utilisateur doivent être adressées au Client et à Prev
                        & Care dans le délai d’un mois suivant la date de fait générateur du dommage. <br/><br/>

                        Dans le cas où la réglementation applicable n’autoriserait pas Prev & Care à limiter ou exclure
                        sa responsabilité à l’égard d’un Utilisateur dans les conditions prévues au présent article 7,
                        cette limitation ou exclusion ne s’appliquera pas et la responsabilité de Prev & Care se
                        limitera à la mesure maximale permise par la loi. <br/><br/>


						<div className="pink medium biggerText marginBottom">8. SYSTEMES D’ENREGISTREMENT</div>

                        Les registres informatisés, conservés dans les systèmes informatiques de Prev & Care et de ses
                        partenaires dans des conditions raisonnables de sécurité, seront considérés comme les preuves
                        des communications et actions des Utilisateurs et de Prev & Care. L’archivage de ces éléments
                        est effectué sur un support fiable et durable de manière à correspondre à une copie fidèle et
                        durable au sens de la réglementation applicable.<br/><br/>

                        Chaque Utilisateur reconnaît la valeur de preuve des systèmes d’enregistrement automatisés de la
                        Plateforme et déclare renoncer à les contester en cas de litige. <br/><br/>


						<div className="pink medium biggerText marginBottom">9. PROPRIETE INTELLECTUELLE</div>

                        A l’exception des contenus mis en ligne par les Utilisateurs, Prev & Care est le propriétaire
                        exclusif de tous les droits de propriété intellectuelle portant sur la Plateforme et les
                        contenus mis à disposition des Utilisateurs dans le cadre de la fourniture des Services, et
                        notamment de façon non limitative sur la charte graphique, le titre, la forme, le plan et la
                        structure, les textes, les logos, les marques, les images et photographies, les animations et
                        vidéos, les slogans, les bases de données, les documents, les newsletters et plus généralement
                        tout ou partie de la Plateforme et des contenus.<br/><br/>

                        Les présentes Conditions Générales nentraînent en principe le transfert daucun de ces droits de
                        propriété intellectuelle au profit d’un Utilisateur. En conséquence, chaque Utilisateur
                        sinterdit de reproduire, utiliser, représenter, modifier, adapter, traduire, copier, diffuser,
                        vendre, publier et exploiter de quelque manière et par quelque procédé que ce soit, tout élément
                        sur lequel Prev & Care détient un droit de propriété intellectuelle, sauf accord préalable écrit
                        de Prev & Care. <br/><br/>

                        En outre, toute extraction, intégration, compilation, ou utilisation à des fins commerciales
                        d’informations contenues dans les bases de données accessibles sur la Plateforme, ainsi que
                        toute utilisation de logiciels, robots, systèmes d’exploration de données et autres outils de
                        collecte de données est strictement interdite aux Utilisateurs. <br/><br/>

                        Prev & Care a consenti au Client dans le Contrat Cadre un droit de licence, incluant un droit de
                        sous-licence au bénéfice des Utilisateurs, non exclusif et non cessible, d’utiliser la
                        Plateforme et les contenus mis à disposition et dont elle détient la pleine propriété, de les
                        télécharger et de les imprimer dans le cadre d’une utilisation personnelle et non
                        commerciale. <br/><br/>

                        Chaque Utilisateur s’interdit de monnayer, vendre, concéder, échanger et plus généralement,
                        négocier tout ou partie de l’accès à la Plateforme, ainsi que les informations et/ou textes,
                        images, contenus audio-visuels et autres contenus exploités par Prev & Care sur la Plateforme
                        et/ou mis en ligne par les Utilisateurs.<br/><br/>

                        Par ailleurs, chaque Utilisateur autorise expressément Prev & Care à utiliser, reproduire,
                        diffuser, héberger, stocker, communiquer, publier, modifier, adapter, traduire et afficher tout
                        contenu, contributions et informations partagés avec les autres Utilisateurs sur la Plateforme,
                        par tous moyens ou procédés, aux fins de l’exploitation, amélioration, vérification, promotion
                        de la Plateforme. Cette licence non-exclusive, transférable et sous-licenciable est valable pour
                        le monde entier, sans redevance, pour la durée de l’inscription de l’Utilisateur ou jusqu’à la
                        suppression du contenu de son compte. <br/><br/>

						<div className="pink medium biggerText marginBottom">10. DUREE, SUSPENSION ET RESILIATION</div>

                        Les présentes Conditions Générales sont conclues&nbsp;et bénéficient aux Utilisateurs pour la
                        durée convenue avec le Client au sein du Contrat Cadre.&nbsp;<br/><br/>

                        L’accès d’un Utilisateur aux Services cessera ainsi de plein droit&nbsp; dans les cas suivants
                        : <br/><br/>

                        - en cas d’expiration ou de résiliation du Contrat Cadre avec le Client, pour quelque motif que
                        ce soit&nbsp;; <br/><br/>

                        - en de cessation des fonctions de l’Utilisateur au sein du Client ou de sortie de ses
                        effectifs&nbsp;;<br/><br/>

                        - en cas de manquement grave par un Utilisateur à ses obligations contractuelles ou au titre des
                        lois et règlements applicables, auquel il n’aurait pas été remédié dans un délai de trente (30)
                        jours (lorsqu’il peut être remédié à ce manquement) suivant notification écrite indiquant la
                        nature du manquement et la nécessité d’y remédier. <br/><br/>

                        Prev & Care se réserve par ailleurs le droit de suspendre l’accès d’un Utilisateur aux Services,
                        de façon temporaire ou définitive, en cas de manquement par ledit Utilisateur à ses obligations.<br/><br/>

                        Tout Utilisateur est libre de ne pas avoir recours aux Services ou de solliciter la suppression
                        de son compte à tout moment auprès de Prev & Care.
						<br/><br/>

						<div className="pink medium biggerText marginBottom">11. AUTONOMIE ET ABSENCE DE RENONCIATION
						</div>

                        Si lune quelconque des stipulations des présentes Conditions Générales était déclarée nulle ou
                        inapplicable pour quelque cause que ce soit en application dune loi, dun règlement ou à la suite
                        dune décision de justice devenue définitive, elle serait réputée non écrite et les autres
                        stipulations resteraient en vigueur.<br/><br/>

                        Le fait pour Prev & Care de ne pas se prévaloir à titre temporaire ou permanent d’une ou de
                        plusieurs dispositions des Conditions Générales n’emportera en aucun cas
                        renonciation. <br/><br/>


						<div className="pink medium biggerText marginBottom">12. MODIFICATION</div>

                        Prev & Care se réserve le droit de modifier à tout moment et sans préavis le contenu ou la
                        localisation de la Plateforme, les Services et les présentes Conditions Générales. <br/><br/>

                        Toute utilisation de la Plateforme ou des Services consécutive à une modification apportée aux
                        Conditions Générales emportera acceptation par chaque Utilisateur desdites modifications. La
                        version la plus récente et en vigueur des Conditions Générales sera toujours disponible à
                        l’adresse suivante&nbsp;: https://www.prevandcare.com/legals-cgu.php<br/><br/>

                        Lorsque les modifications apportées aux Conditions Générales seront considérées par Prev & Care
                        comme substantielles, celles-ci seront portées à la connaissance des Utilisateurs par courrier
                        électronique et devront faire l’objet d’une acceptation par ces derniers lors de leur prochaine
                        connexion sur le Site. <br/><br/>


						<div className="pink medium biggerText marginBottom">13. COMPETENCE ET DROIT APPLICABLE</div>

                        Les présentes Conditions Générales sont soumises à la loi française. <br/><br/>

                        Les litiges qui pourraient survenir quant à la validité, l’interprétation, l’exécution, la
                        modification ou la cessation des présentes Conditions Générales seront soumis à l’appréciation
                        de Prev & Care et du Client en vue d’un règlement amiable. <br/><br/>

                        A défaut de règlement amiable dans un délai d’un mois à compter de la saisine de l’une des
                        parties, tout litige pourra être soumis à la médiation d’un médiateur de la consommation
                        conformément aux articles L612-1 du Code de la consommation.<br/><br/>

                        A défaut de demande de médiation ou en cas d’échec de celle-ci, le litige sera soumis à la
                        compétence exclusive des tribunaux de Paris, même en cas de demande incidente ou en garantie ou
                        de pluralité de parties et y compris pour les procédures d’urgence et les procédures
                        conservatoires.

						<br/><br/>

						<div className="pink medium biggerText marginBottom">Politique de protection des données
                            personnelles
						</div>
                        PREV&amp;CARE est attaché au respect et à la protection des données personnelles. Nous nous
                        engageons à définir et appliquer les mesures adéquates pour garantir la sécurité du traitement
                        de vos données personnelles et ce, afin de protéger les données personnelles que vous nous
                        confiez. Afin de veiller à la bonne application de ces règles, PREV&amp;CARE a désigné un
                        Délégué à la Protection des Données ou Data Protection Officer (DPO) qui est le relais
                        privilégié sur ces sujets.<br/><br/>

                        Cette politique a pour objectif de veiller à la conformité des traitements de données à
                        caractère personnel et le respect des droits des personnes concernées pour la mise en place du
                        site www.prevandcare.com par la société PREV&amp;CARE au regard de la règlementation en vigueur,
                        notamment le règlement 2016/379 relatif à la protection des personnes physiques à légard du
                        traitement des données à caractère personnel et à la libre circulation de ces données, et
                        abrogeant la directive 95/46/CE, dénommée le Règlement Général pour la Protection des
                        Données(RGPD) et la Loi dite &quot; Informatique et Libertés &quot;.<br/><br/>

                        Les notions utilisées dans cette politique sont celles définies dans le RGPD, notamment les
                        notions de traitement, de données personnelles ou données à caractère personnelles, personnes
                        concernées et responsable de traitement.<br/><br/>

						<div className="pink medium biggerText marginBottom">Les principes fondamentaux :</div>
						<div className="pink medium biggerText marginBottom">La finalité des traitements</div>
                        Les données à caractère personnel recueillies par PREV&amp;CARE (notamment via le site
                        Internet), le sont dans le cadre de plusieurs traitements, ayant pour but de :<br/>
                        • répondre à vos demandes exprimées via les formulaires ou en utilisant les moyens de contact
                        disponibles sur le site Internet de PREV&amp;CARE,<br/>
                        • vous contacter au sujet denquêtes de satisfaction ou détudes de marché,<br/>
                        • dans un objectif de prospection commerciale, de communication dinformations ou dinvitation à
                        des événements si vous nous y avez autorisés.<br/>
                        • personnaliser votre interaction avec nos sites, faciliter votre navigation et élaborer des
                        statistiques dutilisation du site Internet de PREV&amp;CARE,<br/>
                        • pour une candidature à un poste au sein de PREV&amp;CARE,<br/>
                        • permettre la fourniture et la bonne exécution des services proposés aux personnes
                        bénéficiaires dans le cadre de son activité <br/>
                        • développer un partenariat avec PREV&amp;CARE<br/><br/>

                        Lors de chaque collecte de données personnelles, la finalité du traitement est
                        précisée.<br/><br/>

                        Le traitement des données personnelles se fonde sur les bases juridiques suivantes: <br/><br/>
                        - les obligations légales et réglementaires auxquelles PREV&amp;CARE est soumise, <br/>
                        - la gestion des relations contractuelles et commerciales, <br/>
                        - l’intérêt légitime de Prev &amp; Care à répondre aux demandes que vous nous adressez, et en
                        vue de la personnalisation ou de l’amélioration du fonctionnement des services et du site
                        internet, et de la tenue de statistiques, de mesures d’audience ou d’études de marché,<br/>
                        - le consentement des bénéficiaires des services proposés dans le cadre de l’activité de
                        PREV&amp;CARE <br/><br/>

                        La consultation du site Internet, le renseignement des formulaires et la réception des
                        communications et actions de prospection PREV&amp;CARE sont bien sûr facultatives. Néanmoins,
                        pour nous permettre de vous recontacter et dadresser au mieux vos besoins et demandes, les
                        données à renseigner dans les formulaires nous sont nécessaires et deviennent obligatoires. A
                        défaut, nous ne pourrions traiter votre demande.<br/><br/>

						<div className="pink medium biggerText marginBottom">La pertinence des données</div>
                        PREV&amp;CARE collecte et traite les données à caractère personnel de manière loyale et licite.
                        La collecte est réalisée directement auprès de vous. Elles visent à vous permettre d’accéder à
                        nos services. Dans un but de transparence, chaque donnée collectée par PREV&amp;CARE est
                        réalisée en toute transparence. Ainsi, il vous sera indiqué dans quel cadre s’effectue cette
                        collecte, sa finalité et la durée.<br/><br/>

                        • Identité (ex : noms, prénoms, coordonnées)<br/>
                        • Données de connexion (ex : inscriptions, cookies)<br/>
                        • PREV&amp;CARE veille à mettre à jour les données vous concernant tout au long des traitements
                        afin que ces dernières soient toujours exactes et actualisées.<br/><br/>

						<div className="pink medium biggerText marginBottom">La conservation limitée des données</div>
                        PREV&amp;CARE ne conserve pas les données à caractère personnel au-delà de la durée nécessaire
                        pour atteindre la finalité du traitement, tout en respectant les limites légales et
                        règlementaires applicables.<br/>
                        Lorsque vous êtes Client et/ou Partenaire de PREV&amp;CARE, vos données sont conservées durant
                        la durée du contrat, et 5 ans à l’expiration dudit contrat, sauf contraintes légales
                        particulières.<br/><br/>

                        Lorsque vous êtes Prospect de PREV&amp;CARE, vos données sont conservées durant 1 an à partir du
                        dernier contact que vous avez eu avec PREV&amp;CARE, sauf contraintes légales
                        particulières.<br/><br/>

                        Lorsque vous êtes un candidat à un poste chez PREV&amp;CARE, vos données de candidature à
                        l’issue de leur examen par nos services ne sont pas conservées, sauf avec votre accord ou lors
                        de votre embauche au sein des effectifs PREV&amp;CARE.<br/><br/>

                        Lorsqu’un internaute se rend sur le site Prev&amp;Care, sur la page d’accueil ou la page
                        secondaire du site, un bandeau l’informera des finalités précises des cookies utilisées, de la
                        possibilité de s’opposer à ces cookies et de changer les paramètres en cliquant sur un lien
                        présent dans le bandeau, du fait que la poursuite de sa navigation vaut accord au dépôt de
                        cookies sur son terminal, les cookies ont une durée de validité de 13 mois.<br/><br/>

                        Lorsque vous bénéficiez des services de PREV&amp;CARE, vos données personnelles seront
                        conservées pendant une durée d’un an au-delà de la durée nécessaire à la réalisation des
                        finalités visées ci-avant, puis seront conservées sous forme anonyme à des fins exclusivement
                        statistiques et ne donneront lieu à aucune exploitation, de quelle que nature que ce soit. <br/><br/>
                        La transmission et la conservation des données personnelles sont indispensables pour permettre
                        la fourniture et bonne exécution des services.<br/><br/>

                        La loi Informatique et Libertés prévoit que vos héritiers, pouvant justifier de leur identité,
                        peuvent demander au responsable de Prev&amp;Care de tenir compte de votre décès et de procéder à
                        l’actualisation de vos données. Une copie de l’acte de décès leur sera demandé.<br/><br/>


						<div className="pink medium biggerText marginBottom">L&spos;accès restreint aux données</div>
                        Seuls les destinataires dûment habilités peuvent accéder, dans le cadre dune politique de
                        gestion des accès, aux informations nécessaires à leur activité.
                        En effet, PREV&amp;CARE définit les règles daccès et de confidentialité applicables aux données
                        personnelles traitées. Seules les personnes au sein de PREV&amp;CARE en charge de votre dossier
                        peuvent avoir accédé à vos données. PREV&amp;CARE impose la même politique aux prestataires avec
                        lesquels elle collabore.<br/><br/>

						<div className="pink medium biggerText marginBottom">La sécurité</div>
                        PREV&amp;CARE détermine et met en œuvre les moyens nécessaires à la protection des données à
                        caractère personnel pour éviter tout accès par toute personne non autorisée et prévenir toute
                        perte, altération ou divulgation de données.<br/><br/>

						<div className="pink medium biggerText marginBottom">L&apos;information et les droits des
                            personnes
						</div>
                        Préalablement à la collecte des données, PREV&amp;CARE informe les personnes concernées de la
                        finalité du traitement, la durée, l’ensemble des éléments notamment via le formulaire de
                        collecte de données. La présente politique est un des éléments d’informations pour le site
                        Internet de PREV&amp;CARE.<br/><br/>

                        Conformément aux lois et règlements en vigueur, notamment le RGPD et la Loi Informatique et
                        Libertés, vous disposez des droits suivants : accès, rectification, effacement, opposition,
                        ainsi que, sous réserve de leur application, le droit de retirer votre consentement, le droit à
                        la limitation du traitement et le droit à la portabilité des données que vous nous avez
                        fournies.<br/><br/>

                        Le cas échéant, vous pouvez déposer une réclamation auprès de la CNIL, sur son site internet
                        www.cnil.fr.<br/><br/>

                        Pour exercer ces droits, vous pouvez écrire au service chargé du respect des droits des
                        personnes (cf. plus bas).<br/><br/>

                        Afin de valider la légitimité de votre demande, une demande signée, accompagnée de la photocopie
                        dun titre didentité valide portant votre signature pourrait vous être demandée. Nous vous
                        demanderons également de nous confier des coordonnées pour vous faire parvenir une réponse.<br/><br/>

						<div className="pink medium biggerText marginBottom">Profilage et scoring : les cookies</div>
                        Afin doptimiser et améliorer la qualité des services qui vous sont proposés et leur adéquation
                        avec vos attentes, PREV&amp;CARE est susceptible dutiliser des &quot; cookies &quot;. En
                        accédant au site Internet de PREV&amp;CARE et en y poursuivant votre navigation, vous fournissez
                        votre accord à limplémentation et lutilisation de cookies sur votre terminal.<br/><br/>

                        Vous reconnaissez avoir pris connaissance de linformation qui vous est fournie concernant
                        lutilisation de ces cookies, et des moyens dont vous disposez notamment pour vous y
                        opposer.<br/><br/>

                        Pour plus dinformations sur lutilisation de cookies par PREV&amp;CARE, veuillez consulter notre
                        Politique des Cookies.<br/><br/>

						<div className="pink medium biggerText marginBottom">Les transferts de données</div>
                        PREV&amp;CARE ne transfère pas de données hors de l’Union Européenne. En revanche, si vous
                        accédez à vos services, notamment à votre espace personnel depuis un pays situé hors de l’Union
                        Européenne, cela s’apparente à un transfert de données. PREV&amp;CARE a pris les mesures pour
                        sécuriser vos données qui intègrent cette hypothèse.<br/><br/>

						<div className="pink medium biggerText marginBottom">Les acteurs de la protection des données
						</div>
                        Le Délégué à la Protection des Données ou Data Protection Officer (DPO)
                        PREV&amp;CARE a désigné un Délégué à la Protection des Données (DPO) PREV&amp;CARE. Le DPO
                        veille à la conformité aux dispositions du RGPD au sein de PREV&amp;CARE.<br/><br/>

                        Le DPO accompagne les équipes lors de la mise en œuvre de traitements, aide linstruction des
                        demandes liées à la protection des données personnelles, informe et sensibilise les
                        collaborateurs. Il est à votre disposition pour répondre aux interrogations que vous pourriez
                        avoir il est joignable à l’adresse suivante : dpo@prevandcare.fr<br/><br/>

                        Le DPO bénéficie de mesures organisationnelles et de moyens lui permettant de piloter la mise en
                        conformité de PREV&amp;CARE.<br/><br/>

						<div className="pink medium biggerText marginBottom">Le Responsable de la Sécurité des Systèmes
                            dInformation (RSSI)
						</div>
                        PREV&amp;CARE a désigné un RSSI PREV&amp;CARE.
                        Le RSSI veille à la bonne gestion de la sécurité de linformation au sein de PREV&amp;CARE via le
                        SMSI mis en œuvre. Il accompagne les équipes sur les sujets de sécurité et sensibilise les
                        collaborateurs.<br/><br/>

						<div className="pink medium biggerText marginBottom">Les autres acteurs</div>
                        Responsable de traitement<br/><br/>
                        Il détermine les finalités et les moyens du traitement. Pour les traitements réalisés via le
                        site Internet de PREV&amp;CARE, il sagit de la société PREV&amp;CARE, domiciliée au 66 avenue de
                        la Grande Armée 75017 Paris, représentée par sa Présidente Bérangère Penaud. Service chargé de
                        la mise en œuvre du traitement.
                        Par délégation du responsable de traitement, le service Marketing de PREV&amp;CARE met en œuvre
                        le site Internet de PREV&amp;CARE.<br/><br/>

						<div className="pink medium biggerText marginBottom">Service chargé du respect des droits des
                            personnes
						</div>
                        Pour exercer vos droits vous pouvez solliciter le DPO de PREV&amp;CARE, en adressant un courrier
                        à : DPO PREV&amp;CARE – 66 avenue de la Grande Armée 75017 Paris.<br/><br/>

						<div className="pink medium biggerText marginBottom">Sous-traitant</div>
                        Il traite des données personnelles pour le compte du responsable de traitement. Il agit sur
                        instruction de PREV&amp;CARE. Il est sous contrat avec PREV&amp;CARE, contrat contenant
                        impérativement les exigences de PREV&amp;CARE en matière de protection des données personnelles.<br/><br/>

						<div className="pink medium biggerText marginBottom">Destinataire</div>
                        Il est habilité à recevoir la communication des données, sur la base du besoin den connaître et
                        du principe de moindre privilège. Les données collectées sur le site Internet de PREV&amp;CARE
                        sont exclusivement réservées à lusage de PREV&amp;CARE et pour la délivrance des services. Elles
                        peuvent être communiquées à nos prestataires pour la réalisation des services, et ne font lobjet
                        daucune cession à des tiers. Afin de répondre au mieux à vos demandes et besoins (par exemple
                        sélection de loffre de service la mieux adaptée, etc.), les données collectées peuvent être
                        partagées au sein de PREV&amp;CARE.<br/><br/>

						<div className="pink medium biggerText marginBottom">Personne concernée</div>
                        Personne à qui appartiennent les données personnelles traitées. Il sagit de vous, qui souhaitez
                        nous fournir vos coordonnées : client, prospect, partenaire, fournisseur, candidat. Droit
                        applicable &quot;La présente politique est régie par le droit Français. <br/>
                        Le présent contrat est régi par la loi française. En cas de contestation sur son interprétation
                        ou sur l’exécution de l’une quelconque de ses stipulations, le CLIENT aura la possibilité de
                        faire appel à un médiateur et à défaut d’accord amiable entre les parties, les tribunaux
                        français seront seuls compétents.<br/><br/>

						<div className="pink medium biggerText marginBottom">En cas de litige, médiation</div>
                        En cas de réclamation, la demande est à adresser au DPO de Prev &amp; Care à ladresse suivant :
                        dpo@prevandcare.com.<br/>
                        A défaut de solution satisfaisante, il est possible dadresser sa réclamation à la Commission
                        nationale Informatique et Liberté depuis leur site www.cnil.fr.
					</div>
				</div>
				<div className={bottom ? "" : "bottom-gradient"}></div>
				<div className="button-bottom">
					<button type="submit" className="button orange">J&apos;ai lu et j&apos;accepte les conditions
                        générales
                        d&apos;utilisation
					</button>
				</div>
			</form>
		</Wrapper>
	);
};

Conditions.propTypes = {
	checkIsLogged: PropTypes.func,
	updateCguState: PropTypes.func,
	dataUser: PropTypes.object,
	session: PropTypes.func,
	updateCaseNoCode: PropTypes.func,
};

export default Conditions;