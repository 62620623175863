import {
	EMAIL_EXISTS,
	EMAIL_TO_CREATE,
	SET_EMAIL_ERROR,
	SET_EMAIL_VALUE,
	SET_PASSWORD_VALUE,
	SET_VERIFY_PASSWORD_VALUE,
	SAVE_USER,
	SAVE_USER_ID,
	SET_ERROR_MESSAGE,
	SET_LOGIN_ERROR_MESSAGE,
	SAVE_USER_TYPE,
	SET_SUCCESS_MESSAGE,
	SET_AUTH,
	SET_REMEMBER_ME,
	SET_REDIRECTION, SET_BLOCKED_ACCOUNT,
} from "../actions/user";

export const initialState = {
	email: "",
	password: "",
	verifiedPassword: "",
	errorMessage: "",
	errorLoginMessage: "",
	successMessage: "",
	passwordError: false,
	emailExist : false,
	emailError: false,
	emailToCreate: false,
	session: {},
	dataUser: {},
	userType: "",
	auth: false,
	rememberMe: false,
	redirection: false,
	blockedAccount : false
};

const user = (state = initialState, action = {}) => {
	switch (action.type) {
	// Set la valeur de l'input
	case SET_EMAIL_VALUE : {
		return {
			...state,
			email: action.value,
		};
	}
	case SET_PASSWORD_VALUE : {
		return {
			...state,
			password: action.value
		};
	}
	case SET_VERIFY_PASSWORD_VALUE : {
		return {
			...state,
			verifiedPassword: action.value
		};
	}
	// Action qui permet de déterminer si l'email est connu en BDD lorsque l'utilisateur soumet son email via la page login
	case EMAIL_EXISTS : {
		return {
			...state,
			emailExist: action.value
		};
	}
	// Action qui permet de déterminer si l'email est connu en BDD lorsque l'utilisateur soumet son email via la page login
	case EMAIL_TO_CREATE : {
		return {
			...state,
			emailToCreate: action.value
		};
	}
	case SET_EMAIL_ERROR: {
		return {
			...state,
			emailError: action.value
		};
	}
	case SET_ERROR_MESSAGE: {
		return {
			...state,
			errorMessage: action.msg
		};
	}
	case SET_LOGIN_ERROR_MESSAGE: {
		return {
			...state,
			errorLoginMessage: action.msg
		};
	}
	case SET_SUCCESS_MESSAGE: {
		return {
			...state,
			successMessage: action.msg
		};
	}
	// Permet de sauvegarder les informations du user renvoyées par le requête checkIsLogged
	case SAVE_USER : {
		return {
			...state,
			session: action.session,
			dataUser: action.dataUser
		};
	}
	case SAVE_USER_ID : {
		return {
			...state,
			idUser: action.idUser
		};
	}
	case SAVE_USER_TYPE : {
		return {
			...state,
			userType: action.userType
		};
	}
	case SET_AUTH : {
		return {
			...state,
			auth: action.bool
		};
	}
	// Action déclencer lorsque la case "se souvenir de moi" est coché
	case SET_REMEMBER_ME : {
		return {
			...state,
			rememberMe: action.bool
		};
	}
	case SET_REDIRECTION : {
		return {
			...state,
			redirection: action.bool
		};
	}
	case SET_BLOCKED_ACCOUNT : {
		return {
			...state,
			blockedAccount: action.bool
		};
	}
	default:
		return state;
	}
};

export default user;