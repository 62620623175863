import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

import "./index.scss";
import App from "./containers/App";
import store from "./store/index";

ReactDOM.render(
	<Provider store={store}>
		<Router basename={"/modules/aidant/login/build/"}>
			<React.StrictMode>
				<App />
			</React.StrictMode>
		</Router>
	</Provider>,
	document.getElementById("root")
);
