import { connect } from "react-redux";

import App from "../../components/App/App";
import { checkIsLogged, loginWithCookie } from "../../actions/user";
import { setACSource, setActivationCode } from "../../actions/activationCode";

const mapStateToProps = (state) => ({
	redirection: state.user.redirection,
	errorApiMessage: state.apiFlag.errorApiMessage,
	activationCode: state.activationCode.activationCode,
	ACSource: state.activationCode.ACSource
});

const mapDispatchToProps = (dispatch) => ({
	checkIsLogged: () => {
		dispatch(checkIsLogged());
	},
	loginWithCookie: () => {
		dispatch(loginWithCookie());
	},
	setActivationCode: (value) => {
		dispatch(setActivationCode(value));
	},
	setACSource: (string) => {
		dispatch(setACSource(string));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(App);