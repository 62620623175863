import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { getBaseLoginUrl } from "../../utils/baseUrl";

const RedirectionToHome = () => {

	useEffect(() => {
		window.location.href = getBaseLoginUrl();
		window.localStorage.clear();
	}, []);
	return (
		<div>
		</div>
	);
};

RedirectionToHome.propTypes = {
	logout: PropTypes.func
};

export default RedirectionToHome;