import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import Eye from "../../../assets/icons/eye.svg";
import CrossedEye from "../../../assets/icons/crossedEye.svg";

const Input = forwardRef(({
	type,
	label,
	src,
	onChange,
	onFocus,
	valueToActive,
	email,
	value,
	fade,
	fadeOut,
	enableEye,
	disabled
}, ref) => {

	const [activeClass, setActiveClass] = useState("");
	const [inputType, setType] = useState(null);
	const [showEye, setShowEye] = useState(false);

	const changeType = () => {
		if (!inputType) {
			setType("text");
			setShowEye(true);
		} else if (inputType === "text") {
			setShowEye(false);
			setType(null);
		}
	};

	return (
		<div className={`input-smooth ${activeClass} ${valueToActive ? "focus-within" : ""} ${fade} ${fadeOut}`}>
			<input
				name={label}
				id={label}
				type={inputType ? inputType : type}
				className={`input-smooth ${email ? "grey focus-within" : ""}`}
				onFocus={() => {
					onFocus();
					setActiveClass("focus-within");
				}}
				onBlur={() => value ? setActiveClass("focus-within") : setActiveClass("")}
				onChange={onChange}
				value={value}
				required
				ref={ref}
				disabled={disabled}
			/>
			<img src={src}/>
			{
				enableEye && !showEye &&
<img className="eye" src={CrossedEye} onClick={changeType} alt=""/>
			}
			{
				showEye &&
<img className="eye" src={Eye} onClick={changeType} alt=""/>
			}
			<label htmlFor={label}>{label}</label>
		</div>
	);
});

Input.propTypes = {
	type: PropTypes.string,
	label: PropTypes.string,
	src: PropTypes.string,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	email: PropTypes.string,
	valueToActive: PropTypes.string,
	value: PropTypes.string,
	fade: PropTypes.string,
	fadeOut: PropTypes.string,
	enableEye: PropTypes.bool,
	disabled: PropTypes.string
};

Input.displayName = "Input";

export default Input;