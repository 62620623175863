import Axios from "axios";
// import SessionService from  "../common/session.service";
import AuthService from "../common/auth.service";
import {
	CHECK_EMAIL,
	CHECK_IS_LOGGED,
	emailExists,
	emailToCreate,
	LOGIN,
	login,
	LOGIN_WITH_COOKIE,
	LOGOUT,
	REGISTER,
	REGISTER_EMAIL,
	registerEmail,
	saveUser,
	saveUserId,
	saveUserType,
	setAuth,
	setBlockedAccount,
	setErrorMessage,
	setLoginErrorMessage,
	setRedirection,
	setSuccessMessage,
	UPDATE_CGU_STATE,
	UPDATE_CODE_ACTIVATION,
	UPDATE_ONBOARDING_STATE
} from "../actions/user";
import { getSession, setSession } from "../common/session.service";
import { getCookie, setCookie } from "../common/cookie.service";
import { getApiBaseUrl } from "../utils/baseUrl";
import { setErrorApiMessage } from "../actions/apiFlag";

Axios.defaults.timeout = 10000;

const auth = (store) => (next) => (action) => {
	switch (action.type) {
	case CHECK_IS_LOGGED : {
		const url = `${getApiBaseUrl()}/checkLogin/`;
		Axios.post(url, getSession(), { withCredentials: true })
			.then((res) => {
				if (res.data.status === "success") {
					store.dispatch(setAuth(true));
					// Sauvegarde de
					store.dispatch(saveUser(res.data.dataUser));
				}
			})
			.catch((err) => {
				if (err.response.status === 401) {
					store.dispatch(setAuth(false));
					window.localStorage.clear();
				}
				setTimeout(() => {
					store.dispatch(setErrorApiMessage(false));
				}, 5000);
			});
		next(action);
		break;
	}
	case CHECK_EMAIL: {
		const state = store.getState();
		const { user } = state;

		// On envoie la valeur que l'utilisateur à renseignée dans le champ dédié à l'email
		const payload = {
			"type": "adherent",
			"email": user.email,
		};

		const url = `${getApiBaseUrl()}/checkEmail/`;
		Axios.post(url, payload, { withCredentials: true })
			.then((res) => {
				// Si l'email existe (true) on change emailExist à true
				// S'il l'email n'existe pas, on change emailExist à false
				// La valeur de la variable emailExist permettra de conditionner l'affichage des inputs/bouttons appropriés
				res.data.bool === true ? store.dispatch(emailExists(true)) : store.dispatch(emailExists(false));
				res.data.bool === false ? store.dispatch(saveUserType("newUser")) : null;
				res.data.bool === false ? store.dispatch(emailToCreate(true)) : null;
				user.emailExist === false ? store.dispatch(registerEmail()) : null;
			})
			.catch((err) => {
				if (err) {
					if (err.code === "ECONNABORTED") {
						store.dispatch(setErrorApiMessage(true));
					}
					setTimeout(() => {
						store.dispatch(setErrorApiMessage(false));
					}, 5000);
				}
			});
		next(action);
		break;
	}
	case LOGIN_WITH_COOKIE : {
		const password = getCookie("password", "doNotParse");
		const idUser = getCookie("idUser", "doNotParse");
		const email = getCookie("email", "doNotParse");

		if (password && idUser && email) {
			const payload = {
				"type": "adherent",
				"email": email,
				"password": atob(password),
			};

			AuthService.login(payload, { withCredentials: true }).then((res) => {
				if (res.status !== 400) {
					store.dispatch(setAuth(true));
					store.dispatch(setRedirection(true));
				} else {
					store.dispatch(setErrorMessage("Mauvaise combinaison email/mot de passe"));
					store.dispatch(setSuccessMessage(""));
				}
			});
		}
		next(action);
		break;
	}
	case LOGIN : {
		const state = store.getState();
		const { user } = state;

		const payload = {
			"type": "adherent",
			"email": user.email,
			"password": user.password,
		};

		AuthService.login(payload, { withCredentials: true })
			.then((res) => {
				if (res.status !== 400) {
					store.dispatch(setErrorMessage(""));
					store.dispatch(setAuth(true));
					store.dispatch(setSuccessMessage("Authentification réussie, vous allez être redirigé..."));
					store.dispatch(setLoginErrorMessage(""));
					if (user.rememberMe && window.localStorage.getItem("stateCgu") == 1 && window.localStorage.getItem("stateOnboarding") == 1) {
						let date = new Date();
						date.setMonth(date.getMonth() + 3);
						const option = { path: "/", expires: date };
						setCookie("idUser", getSession().session.idUser, option);
						setCookie("email", getSession().session.email, option);
						setCookie("password", window.btoa(user.password), option);
					}
				} else if (res.data.message !== "blockedAccount") {
					store.dispatch(setLoginErrorMessage("Mauvaise combinaison email/mot de passe"));
					store.dispatch(setSuccessMessage(""));
				} else {
					// COMPTE BLOQUÉ
					store.dispatch(setBlockedAccount(true));
				}
			});
		next(action);
		break;
	}
	case REGISTER_EMAIL : {
		const state = store.getState();
		const { user } = state;
		const { email } = user;
		const url = `${getApiBaseUrl()}/register/`;

		const payload = {
			"email": email,
		};

		Axios.post(url, payload, { withCredentials: true })
			.then((res) => {
				store.dispatch(saveUserId(res.data.idUser));
			})
			.catch((err) => {
				if (err.response.status === 401) {
					store.dispatch(setRedirection(true));
				}
				if (err) {
					if (err.code === "ECONNABORTED") {
						store.dispatch(setErrorApiMessage(true));
					}
					setTimeout(() => {
						store.dispatch(setErrorApiMessage(false));
					}, 5000);
				}
			});
	}

		next(action);
		break;
	case REGISTER : {
		const state = store.getState();
		const { user } = state;
		const { email, password, verifiedPassword, idUser } = user;

		const url = `${getApiBaseUrl()}/register/`;

		const payload = {
			"email": email,
			"password": password,
			"password-confirm": verifiedPassword,
			"idUser": idUser
		};

		Axios.post(url, payload, { withCredentials: true })
			.then(async (res) => {
				const session = {
					"email": res.data.email,
					"idUser": res.data.idUser,
					"state": res.data.state,
				};

				if (res.data.status === "success") {
					// Enregistrement de la session dans le localStorage qui sont utlisé pour
					// Vérifier l'auteur des appels API
					setSession(res.data.idUser, res.data.email, res.data.state);
					await store.dispatch(setSuccessMessage(""));
					await store.dispatch(setErrorMessage(""));
					await store.dispatch(setSuccessMessage("Vous avez bien été enregistré, vous allez maintenant être redirigé"));
					store.dispatch(saveUser(session));
					store.dispatch(login());
				}

				// =========== ERROR ===========
				if (res.data.status === "error") {
					store.dispatch(setLoginErrorMessage(""));
					await store.dispatch(setErrorMessage(""));
					await store.dispatch(setErrorMessage(res.data.message));
				}

			})
			.catch((err) => {
				if (err.response.status === 401) {
					store.dispatch(setRedirection(true));
				}
				if (err) {
					if (err.code === "ECONNABORTED") {
						store.dispatch(setErrorApiMessage(true));
					}
					setTimeout(() => {
						store.dispatch(setErrorApiMessage(false));
					}, 5000);
				}
			});
	}

		next(action);
		break;
	case UPDATE_CGU_STATE: {
		const state = store.getState();
		const { user } = state;
		const url = `${getApiBaseUrl()}/updateUser/`;

		const payload = {
			"session": {
				"id": window.localStorage.getItem("id"),
				"email": user.session.email,
				"idUser": user.session.id,
			},
			"toUpdate": {
				"stateCgu": 1
			}
		};

		Axios.post(url, payload, { withCredentials: true })
			.then(() => null)
			.catch((err) => {
				if (err.response.status === 401) {
					store.dispatch(setRedirection(true));
				}
				if (err) {
					if (err.code === "ECONNABORTED") {
						store.dispatch(setErrorApiMessage(true));
					}
					setTimeout(() => {
						store.dispatch(setErrorApiMessage(false));
					}, 5000);
				}
			});
	}
		next(action);
		break;
	case UPDATE_ONBOARDING_STATE: {
		const state = store.getState();
		const { user } = state;
		const url = `${getApiBaseUrl()}/updateUser/`;

		const payload = {
			"session": {
				"id": window.localStorage.getItem("id"),
				"email": user.session.email,
				"idUser": user.session.id,
			},
			"toUpdate": {
				"stateOnboarding": 1
			}
		};

		if (user.rememberMe) {
			let date = new Date();
			date.setMonth(date.getMonth() + 3);
			const option = { path: "/", expires: date };
			setCookie("idUser", getSession().session.idUser, option);
			setCookie("email", getSession().session.email, option);
			setCookie("password", window.btoa(user.password), option);
		}

		Axios.post(url, payload, { withCredentials: true })
			.then(() => null)
			.catch((err) => {
				if (err.response.status === 401) {
					store.dispatch(setRedirection(true));
				}
				if (err) {
					if (err.code === "ECONNABORTED") {
						store.dispatch(setErrorApiMessage(true));
					}
					setTimeout(() => {
						store.dispatch(setErrorApiMessage(false));
					}, 5000);
				}
			});
	}
		next(action);
		break;
	case UPDATE_CODE_ACTIVATION: {
		const state = store.getState();
		const { user } = state;
		const url = `${getApiBaseUrl()}/updateUser/`;

		const payload = {
			"session": {
				"id": window.localStorage.getItem("id"),
				"email": user.session.email,
				"idUser": user.session.id,
			},
			"toUpdate": {
				"idExt": action.idExt,
				"idSociete": action.idSociete
			}
		};

		Axios.post(url, payload, { withCredentials: true })
			.then(() => null)
			.catch((err) => {
				if (err.response.status === 401) {
					store.dispatch(setRedirection(true));
				}
				if (err) {
					if (err.code === "ECONNABORTED") {
						store.dispatch(setErrorApiMessage(true));
					}
					setTimeout(() => {
						store.dispatch(setErrorApiMessage(false));
					}, 5000);
				}
			});
	}
		next(action);
		break;
	case LOGOUT: {

		const url = `${getApiBaseUrl()}/logout/`;

		Axios.post(url)
			.then(() => {
				window.localStorage.clear();
				setTimeout(() => {
					window.location.href = `${process.env.REACT_APP_LOCAL_URL}`;
				}, 1000);
			})
			.catch((err) => {
				if (err.response.status === 401) {
					store.dispatch(setRedirection(true));
				}
				if (err) {
					if (err.code === "ECONNABORTED") {
						store.dispatch(setErrorApiMessage(true));
					}
					setTimeout(() => {
						store.dispatch(setErrorApiMessage(false));
					}, 5000);
				}
			});
	}
		next(action);
		break;
	default:
		next(action);
	}
};

export default auth;