import { getApiBaseUrl } from "./baseUrl";
import Axios from "axios";

export const getBrandValues = async () => {
	const url = `${getApiBaseUrl()}/brands-colors.json`;
	try {
		const res = await Axios.post(url); // Utilisation de "await" pour attendre la réponse
		const brands = res.data; // Supposons que la réponse contient un tableau
		const brandValues = brands.find(
			(config) => config.hostame === window.location.hostname
		);
		return brandValues; // Retourne les valeurs trouvées
	} catch (error) {
		console.error(
			"Erreur lors de la récupération des valeurs de marque :",
			error
		);
		return null; // En cas d'erreur, retourne null
	}
};
