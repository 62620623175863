import { createStore, compose, applyMiddleware } from "redux";

import rootReducer from "../reducers";
import auth from "../middlewares/auth";
import activationCode from "../middlewares/activationCode";
import ApiService from "../common/api.service";

// Nécessaire pour l'activation du debugger de l'extension chrome :
// https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

ApiService.init();

// Création d'une variable contenant les middlewares à appliquer
const enhancers = composeEnhancers(
	applyMiddleware(
		auth,
		activationCode
	),
);

// Création du store composé de tous les reducers
const store = createStore(
	rootReducer,
	enhancers,
);

export default store;