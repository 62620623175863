import { connect } from "react-redux";
import { checkActivationCode, setActivationCode, setShowModal, updateCaseNoCode } from "../../../actions/activationCode";
import Modal from "../../../components/LoginTunnel/ActivationCode/Modal";
import { checkIsLogged, setSuccessMessage } from "../../../actions/user";

export const MapStateToProps = (state) => ({
	showModal : state.activationCode.showModal,
	activationCode: state.activationCode.activationCode,
	errorActivation: state.activationCode.errorActivation,
	successActivation: state.activationCode.successActivation,
	dataUser: state.user.dataUser,
	session: state.user.session,
});

export const MapDispatchToProps = (dispatch) => ({
	setShowModal: (bool) => {dispatch(setShowModal(bool));},
	setActivationCode: (value) => {dispatch(setActivationCode(value));},
	checkActivationCode: () => {dispatch(checkActivationCode());},
	checkIsLogged: () => {dispatch(checkIsLogged());},
	updateCaseNoCode: () => {dispatch(updateCaseNoCode());},
	setSuccessMessage: () => {dispatch(setSuccessMessage());},
});

export default connect(MapStateToProps, MapDispatchToProps)(Modal);